<template>
  <div class="ces-main" style="height: 100%">
    <div class="boxbutton">
      <el-row>
        <el-col :span="19">
          <div class="grid-content bg-purple">
            <!--<el-button type="primary" plain size="mini" v-if="waybillSearchFlag" @click="waybillManagementSearch">查询</el-button>-->
            <el-button type="primary" plain size="mini" v-if="waybillManagementAddFlag" @click="waybillManagementAdd">新增</el-button>
            <el-button type="primary" plain size="mini" v-if="waybillModifyFlag" @click="waybillManagementModify">修改</el-button>
            <el-button type="primary" plain size="mini" v-if="waybillReleaseFlag" @click="waybillManagementRelease">发布</el-button>
            <el-button type="primary" plain size="mini" v-if="waybillwaybillPublishFallbackFlag" @click="waybillPublishFallback">发布回退</el-button>
            <el-button type="primary" plain size="mini" v-if="waybillServiceFlag" @click="waybillManagementService">送达</el-button>
            <el-button type="primary" plain size="mini" v-if="waybillCancelFlag" @click="waybillManagementCancel">撤单</el-button>
            <el-button type="primary" plain size="mini" v-if="waybillCollectFlag" @click="waybillManagementCollect">揽收</el-button>
            <el-button type="primary" plain size="mini" v-if="waybillCollectBackFlag" @click="waybillManagementCollectBack">揽收回退</el-button>
            <el-button type="primary" plain size="mini" v-if="waybillManagementReceiptUploadFlag" @click="waybillManagementReceiptUpload"
              >回单上传</el-button
            >
            <el-button type="primary" plain size="mini" v-if="waybillreceiptConfirmationFlag" @click="receiptConfirmation">回单确认</el-button>
            <el-tooltip class="item" effect="dark" content="提示：请先选择查询条件再进行导出." placement="bottom">
              <el-button type="primary" plain size="mini" v-if="waybillExportFlag" @click="waybillManagementExport">导出</el-button>
            </el-tooltip>
            <el-button type="primary" plain size="mini" v-if="consignmentFlag" @click="consignment">托运单A5纸补打</el-button>
            <el-button type="primary" plain size="mini" v-if="handoverFlag" @click="handover">交接单补打</el-button>
            <el-button type="primary" plain size="mini" v-if="immovableFlag" @click="immovable">托运单五联纸补打</el-button>
            <el-button type="primary" plain size="mini" v-if="waybillSearchFlagA" @click="handleClose" :loading="loading">{{
              loading ? '查询中 ...' : '高级查询刷新'
            }}</el-button>
            <el-button type="primary" plain size="mini" @click="handleNote">运单短信发送设置</el-button>
            <el-tooltip class="item" effect="dark" content="提示：请先选择查询条件再进行导出." placement="bottom">
              <el-button type="primary" plain size="mini" v-if="exportWayBillTime" @click="exportWayBillTimeEfficiencyByDate">导出运单时效</el-button>
            </el-tooltip>

            <el-button
              type="primary"
              plain
              size="mini"
              @click="derive"
              v-if="
                currentPageButtons.find(item => {
                  return item.menuId == '2_2_2_17'
                })
              "
              >导出上月运单时效</el-button
            >
          </div>
        </el-col>
        <el-col :span="5">
          <div class="grid-content bg-purple">
            <el-input
              maxlength="50"
              placeholder="请输入运单号"
              v-model="AccurateAndUnique_orderNo"
              size="mini"
              style="width: 160px"
              class="input-with-input"
            >
              <el-button slot="append" icon="el-icon-search" @click="handleQuery"></el-button>
            </el-input>
            <el-button type="primary" size="mini" icon="el-icon-zoom-in" style="float: right" @click="handleSearch"></el-button>
          </div>
        </el-col>
        <el-drawer
          title="运单管理高级查询条件"
          :before-close="cancelForm"
          :visible.sync="dialog"
          :with-header="false"
          direction="rtl"
          custom-class="demo-drawer"
          ref="drawer"
        >
          <div class="demo-drawer__content">
            <el-row>
              <el-col>
                <div class="grid-content bg-purple">
                  <el-card class="box-card">
                    <div>
                      <el-form label-width="100px" :model="dataForms" ref="dataForms" size="mini" label-position="left" style="height: 80%">
                        <el-form-item label="单据状态" prop="status">
                          <el-select v-model="dataForms.status" placeholder="请选择单据状态" style="width: 100%">
                            <el-option label="请选择" value=""></el-option>
                            <el-option label="正常" value="0"></el-option>
                            <el-option label="撤单" value="1"></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="物流状态" prop="transStatus">
                          <el-select v-model="dataForms.transStatus" placeholder="请选择" style="width: 100%">
                            <el-option label="请选择" value=""></el-option>
                            <el-option label="初始" value="0"></el-option>
                            <el-option label="已发布" value="2"></el-option>
                            <el-option label="已揽收" value="4"></el-option>
                            <el-option label="派送中" value="6"></el-option>
                            <el-option label="已送达" value="8"></el-option>
                            <el-option label="已签收" value="10"></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="是否正泰" prop="chintBusiness">
                          <el-select v-model="dataForms.chintBusiness" placeholder="请选择" style="width: 100%">
                            <el-option label="请选择" value=""></el-option>
                            <el-option label="是" value="0"></el-option>
                            <el-option label="否" value="1"></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="是否外阜" prop="outGoing">
                          <el-select v-model="dataForms.outGoing" placeholder="请选择" style="width: 100%">
                            <el-option label="请选择" value=""></el-option>
                            <el-option label="市内" value="0"></el-option>
                            <el-option label="外阜" value="1"></el-option>
                          </el-select>
                        </el-form-item>
                        <CityLinkageReg @addlist="getCity" :addList="addList"></CityLinkageReg>
                        <!--                                                <el-form-item label="客户名称" prop="companyName">-->
                        <!--                                                    <el-input  maxlength="18" v-model="dataForms.companyName"></el-input>-->
                        <!--                                                </el-form-item>-->
                        <el-form-item label="回单状态" prop="receiptStatus">
                          <el-select v-model="dataForms.receiptStatus" placeholder="请选择" style="width: 100%">
                            <el-option label="请选择" value=""></el-option>
                            <el-option label="未上传回单" value="0"></el-option>
                            <el-option label="上传未确认" value="1"></el-option>
                            <el-option label="确认不通过" value="2"></el-option>
                            <el-option label="确认通过" value="3"></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="线路" prop="vendeeLine">
                          <el-input maxlength="18" v-model="dataForms.vendeeLine"></el-input>
                        </el-form-item>
                        <el-form-item label="批次号" prop="batchNo">
                          <el-input v-model="dataForms.batchNo"></el-input>
                        </el-form-item>
                        <el-form-item label="收货方名称" prop="vendeeName">
                          <el-input maxlength="18" v-model="dataForms.vendeeName"></el-input>
                        </el-form-item>
                        <el-form-item label="收货人" prop="vendeeLinkman">
                          <el-input maxlength="18" v-model="dataForms.vendeeLinkman" placeholder="支持模糊搜索"></el-input>
                        </el-form-item>
                        <el-form-item label="发货方名称" prop="consignName">
                          <el-input maxlength="18" v-model="dataForms.consignName"></el-input>
                        </el-form-item>
                        <el-form-item label="司机名称" prop="driver">
                          <el-input maxlength="18" v-model="dataForms.driver"></el-input>
                        </el-form-item>
                        <el-form-item label="车牌号" prop="truckNo">
                          <el-input maxlength="18" v-model="dataForms.truckNo"></el-input>
                        </el-form-item>
                        <el-form-item label="创建开始时间" prop="createTimeBegin">
                          <el-date-picker
                            style="width: 100%"
                            v-model="dataForms.createTimeBegin"
                            type="date"
                            placeholder="选择日期时间"
                            align="right"
                          >
                          </el-date-picker>
                        </el-form-item>
                        <el-form-item label="创建结束时间" prop="createTimeEnd">
                          <el-date-picker style="width: 100%" v-model="dataForms.createTimeEnd" type="date" placeholder="选择日期时间" align="right">
                          </el-date-picker>
                        </el-form-item>
                      </el-form>
                      <div slot="footer" class="dialog-footer" style="text-align: center">
                        <!--<el-button type="primary" @click="$refs.drawer.closeDrawer()" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>-->
                        <el-button size="mini" v-if="waybillSearchFlag" type="primary" @click="handleClose" :loading="loading">{{
                          loading ? '提交中 ...' : '查 询'
                        }}</el-button>
                        <el-button size="mini" type="warning" @click="cancelForm">关闭</el-button>
                        <el-button size="mini" type="danger" @click="resetFrom">重置</el-button>
                      </div>
                    </div>
                  </el-card>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-drawer>
      </el-row>
    </div>
    <div class="tableMain">
      <ces-table
        size="mini"
        :isSelection="true"
        :isIndex="true"
        :isPagination="true"
        :isHandle="true"
        v-loading="loading"
        :tableData="tableData"
        :tableCols="tableCols"
        :pagination="pagination"
        @refresh="handleCurrentChange"
        @handleImgClick="handleImgClick"
        @handleProNoderClick="handleProNoderClick"
        ref="cesTable"
      >
      </ces-table>
    </div>
    <!-- 短信弹窗 -->
    <el-dialog title=" 运单短信发送设置" :visible.sync="noteVisible" width="55%" center @close="handleCloseNote">
      <div style="margin-bottom: 15px; text-align: center">是否发送短信（勾选则发送）</div>
      <div style="text-align: center">派送 <el-checkbox v-model="receive" :true-label="1" :false-label="0"></el-checkbox></div>
      <div style="margin-bottom: 5px; text-align: center">送达 <el-checkbox v-model="delivery" :true-label="1" :false-label="0"></el-checkbox></div>

      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="handleUpdate">修改</el-button>
        <el-button @click="noteVisible = false">关闭</el-button>
      </span>
    </el-dialog>
    <!--< 新增,新建运单>-->
    <el-dialog
      :title="dialog_waybillMan_Title_Flag"
      :visible.sync="dialogVisible_waybilladd"
      width="85%"
      :modal="true"
      center
      :before-close="handleClose_waybilladd"
    >
      <el-form
        :model="waybillDoForm"
        ref="waybillDoForm"
        :rules="waybillDoFormrules"
        label-width="110px"
        class="demo-ruleForm"
        size="mini"
        label-position="center"
      >
        <el-card class="box-card" :body-style="{ padding: '10px' }">
          <div slot="header">
            <span style="font-size: 14px">发货方信息</span>
            <el-button type="primary" plain size="mini" style="float: right" @click="header_clearone">清除发货方</el-button>
          </div>
          <div>
            <el-row>
              <el-col :span="10">
                <el-form-item label="发货方名称" prop="consignName">
                  <el-input v-model="waybillDoForm.consignName" @focus="consignFocus"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <SellerListCityLinkage :disabled="true" @SellerList="getSellerListCityLinkageCity" :SellerList="SellerList"></SellerListCityLinkage>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="发货方地址" prop="consignAddress">
                  <el-input disabled v-model="waybillDoForm.consignAddress"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="发货方联系人" prop="consignLinkman">
                  <el-input disabled v-model="waybillDoForm.consignLinkman"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="发货方手机" prop="consignMobile">
                  <el-input disabled v-model="waybillDoForm.consignMobile"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="承诺到货时间" prop="replyDeliveryTime">
                  <!-- <el-input disabled v-model="waybillDoForm.replyDeliveryTime"></el-input> -->
                  <el-date-picker
                    v-model="waybillDoForm.replyDeliveryTime"
                    type="datetime"
                    style="width: 100%"
                    placeholder="选择日期时间"
                    align="right"
                    :picker-options="pickerOptions"
                  >
                  </el-date-picker>
                  <span>备注:填写该字段，则时效按照该时间计算</span>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="备注" prop="remark">
                  <el-input v-model="waybillDoForm.remark"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-card>
        <el-card class="box-card" :body-style="{ padding: '10px' }" style="margin-top: 10px; margin-bottom: 10px">
          <div slot="header">
            <span style="font-size: 14px">收货方信息</span>
            <el-button type="primary" plain size="mini" style="float: right" @click="header_clear">清除收货方</el-button>
          </div>
          <div>
            <el-row>
              <!--<el-col :span="6">-->
              <!--<el-form-item label="收货方编码" prop="vendeeId">-->
              <!--<el-input v-model="vendee.vendeeId"></el-input>-->
              <!--</el-form-item>-->
              <!--</el-col>-->
              <el-col :span="10">
                <el-form-item label="收货方名称" prop="vendeeName">
                  <el-input v-model="waybillDoForm.vendeeName" @focus="vendeeFocus"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <BuyerListCityLinkage :disabled="true" @BuyerList="getBuyerListCityLinkageCity" :BuyerList="BuyerList"></BuyerListCityLinkage>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="收货方地址" prop="vendeeAddress">
                  <el-input disabled v-model="waybillDoForm.vendeeAddress"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="收货方联系人" prop="vendeeLinkman">
                  <el-input disabled v-model="waybillDoForm.vendeeLinkman"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="收货方手机" prop="vendeeMobile">
                  <el-input disabled v-model="waybillDoForm.vendeeMobile"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="线路名称" prop="vendeeLine">
                  <el-input disabled v-model="waybillDoForm.vendeeLine"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="线路类型" prop="outGoing">
                  <el-select disabled v-model="waybillDoForm.outGoing" placeholder="请选择">
                    <el-option label="市内" :value="0"></el-option>
                    <el-option label="外阜" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="线路时效类型" prop="timelinessType">
                  <el-select disabled v-model="waybillDoForm.timelinessType" placeholder="请选择">
                    <el-option label="固定值" :value="1"></el-option>
                    <el-option label="小时" :value="2"></el-option>
                    <el-option label="某天某时刻" :value="3"></el-option>
                    <el-option label="某天某时某分" :value="4"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="线路时效" prop="timeliness">
                  <el-input disabled v-model="waybillDoForm.timeliness"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="要求到货时间" prop="requiredDeliveryTime">
                  <el-input disabled v-model="waybillDoForm.requiredDeliveryTime"></el-input>
                  <span>备注:该时间根据线路时效，自动算出，无需填写</span>

                  <!--                  <el-date-picker-->
                  <!--                      v-model="waybillDoForm.requiredDeliveryTime"-->
                  <!--                      type="datetime"-->
                  <!--                      style="width: 100%"-->
                  <!--                      placeholder="选择日期时间"-->
                  <!--                      align="right"-->
                  <!--                      :picker-options="pickerOptions">-->
                  <!--                  </el-date-picker>-->
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="收货方固话" prop="vendeePhone">
                  <el-input disabled v-model="waybillDoForm.vendeePhone"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-card>

        <div style="text-align: center">
          <span slot="footer" class="dialog-footer" style="text-align: center">
            <el-button type="primary" @click="savewaybill">保存(关闭页面)</el-button>
            <el-button type="primary" v-if="saveAndAddFlag" @click="saveAndAdd">保存(不关闭页面）</el-button>
            <el-button @click="handleClose_waybilladd">关闭</el-button>
          </span>
        </div>

        <el-card class="box-card" :body-style="{ padding: '10px' }" style="margin-top: 10px">
          <div slot="header">
            <span style="font-size: 14px">物料明细汇总</span>
          </div>
          <div>
            <el-row>
              <el-col :span="6">
                <el-form-item label="总件数" prop="pkgQty">
                  <!--<el-input v-model="waybillDoForm.pkgQty"></el-input>-->
                  <el-input-number
                    style="width: 100%"
                    v-model="waybillDoForm.pkgQty"
                    :min="0"
                    :max="100000"
                    @input.native="onInput0_100"
                    :controls="false"
                    :precision="0"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="总毛重（kg）" prop="weight">
                  <el-input-number
                    style="width: 100%"
                    v-model="waybillDoForm.weight"
                    :min="0"
                    :max="99999.99"
                    @input.native="onInput0_200"
                    :controls="false"
                    :precision="2"
                    onKeypress="return (/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
                  ></el-input-number>
                  <!--<el-input v-model="waybillDoForm.weight"></el-input>-->
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="总体积(m³)" prop="volume">
                  <el-input-number
                    style="width: 100%"
                    v-model="waybillDoForm.volume"
                    :min="0"
                    :max="99999.9999"
                    @input.native="onInput0_200"
                    :controls="false"
                    :precision="4"
                  ></el-input-number>
                  <!--<el-input v-model="waybillDoForm.volume"></el-input>-->
                </el-form-item>
              </el-col>
            </el-row>
            <!--<el-row>-->
            <!--<el-col :span="6">-->
            <!--<el-form-item label="重量单位" prop="wunit">-->
            <!--<el-input v-model="waybillDoForm.wunit"></el-input>-->
            <!--</el-form-item>-->
            <!--</el-col>-->
            <!--<el-col :span="6">-->
            <!--<el-form-item label="体积单位" prop="vunit">-->
            <!--<el-input v-model="waybillDoForm.vunit"></el-input>-->
            <!--</el-form-item>-->
            <!--</el-col>-->
            <!--</el-row>-->
            <el-row>
              <el-col :span="24">
                <el-form-item label="提示:">
                  <el-link :underline="false" type="danger"
                    >总件数、总重量、总体积的数量计算会依据物料明细各项数据自动汇总，无特殊情况外，请尽量不要人工填写该模块。</el-link
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-form>

      <el-card class="box-card materialTableData" :body-style="{ padding: '10px' }" style="margin-top: 10px">
        <div slot="header">
          <span style="font-size: 14px">物料明细</span>
          <el-button type="success" size="mini" style="float: right" @click="importExcelData">导入物料数据</el-button>
        </div>
        <div>
          <el-row>
            <el-button type="primary" plain size="mini" @click="addrow">新增一行</el-button>
            <el-button type="primary" plain size="mini" v-if="editFlag" @click="edit">开启编辑状态</el-button>
            <el-button type="primary" plain size="mini" v-if="saveFlag" @click="save">关闭编辑状态</el-button>
          </el-row>
          <el-row>
            <el-table :data="materialTableData" border style="width: 100%; height: 100%">
              <el-table-column label="物料名称" width="300" align="center">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.materialName" v-show="scope.row.iseditor" @blur="materialNameTleBlur"></el-input>
                  <span v-show="!scope.row.iseditor">{{ scope.row.materialName }}</span>
                </template>
              </el-table-column>
              <el-table-column label="件数" width="180" align="center">
                <template slot-scope="scope">
                  <el-input-number
                    style="width: 100%"
                    v-model="scope.row.pkgQty"
                    v-show="scope.row.iseditor"
                    :min="1"
                    :max="100000"
                    @input.native="onInput0_100"
                    :controls="false"
                    :precision="0"
                    @blur="materialSummation"
                  ></el-input-number>
                  <!--<el-input v-model="scope.row.pkgQty" v-show="scope.row.iseditor"></el-input>-->
                  <span v-show="!scope.row.iseditor">{{ scope.row.pkgQty }}</span>
                </template>
              </el-table-column>
              <el-table-column label="毛重（kg）" width="180" align="center">
                <template slot-scope="scope">
                  <el-input-number
                    style="width: 100%"
                    v-model="scope.row.weight"
                    v-show="scope.row.iseditor"
                    :min="0.01"
                    :max="99999.99"
                    @input.native="onInput0_200"
                    :controls="false"
                    :precision="2"
                    @blur="materialSummation"
                  ></el-input-number>
                  <!--<el-input v-model="scope.row.weight" v-show="scope.row.iseditor" oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>-->
                  <span v-show="!scope.row.iseditor">{{ scope.row.weight }}</span>
                </template>
              </el-table-column>
              <el-table-column label="体积（m³）" width="180" align="center">
                <template slot-scope="scope">
                  <el-input-number
                    style="width: 100%"
                    v-model="scope.row.volume"
                    v-show="scope.row.iseditor"
                    :min="0.0001"
                    :max="99999.9999"
                    @input.native="onInput0_200"
                    :controls="false"
                    :precision="4"
                    @blur="materialSummation"
                  ></el-input-number>
                  <!--<el-input v-model="scope.row.volume" v-show="scope.row.iseditor" oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>-->
                  <span v-show="!scope.row.iseditor">{{ scope.row.volume }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </div>
      </el-card>
    </el-dialog>
    <el-dialog title="发货方查询" :visible.sync="dialog_comm_carriers" @close="close_dialog_comm_carriers">
      <el-row>
        <el-form :inline="true" v-model="carriers_form" size="mini" label-position="center">
          <el-col :span="9">
            <div class="grid-content">
              <el-form-item label="编码" label-width="90px" style="width: 250px">
                <el-input size="mini" v-model="carriers_form.carriersId" style="width: 150px"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="9">
            <div class="grid-content">
              <el-form-item label="名称" label-width="90px" style="width: 250px">
                <el-input size="mini" v-model="carriers_form.carriersName" style="width: 150px"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <el-button type="primary" plain size="mini" @click="companySearch">查询</el-button>
              <el-button type="primary" plain size="mini" @click="companyOkay">确定</el-button>
            </div>
          </el-col>
        </el-form>
      </el-row>
      <el-row class="waybill_add">
        <flight-table
          :tableData="tableDatadialog"
          :columns="columns"
          :pagesizes="pagesizes"
          @handleDelete="handleDelete"
          @handleSelectionChange="handleSelectionChange"
          @handleCurrentChange="handleCurrentChange_compan"
          @clickTable="clickTable"
          @handleEdit="handleEdit"
          @handleSizeChange="handleSizeChange"
          :total="tableDatadialog_pagination.total"
          :pageSize="tableDatadialog_pagination.pageSize"
          :current-page="tableDatadialog_pagination.pageNum"
        ></flight-table>
      </el-row>
    </el-dialog>
    <el-dialog
      title="回单上传"
      :visible.sync="dialog_waybillManagementReceiptUpload"
      width="50%"
      :modal="true"
      center
      :before-close="handleClose_waybillManagementReceiptUpload"
    >
      <el-card class="box-card" :body-style="{ padding: '10px' }">
        <div slot="header">
          <span style="font-size: 14px">回单上传</span>
        </div>
        <div>
          <el-form :model="saveReceipt" label-width="110px" class="demo-ruleForm" size="mini" label-position="center">
            <el-row>
              <el-col :span="24">
                <el-form-item label="运单编号" prop="orderNo">
                  <el-link :underline="false" type="primary">{{ saveReceipt.orderNo }}</el-link>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="回单附件照片">
                  <el-image style="width: 100px; height: 100px" :src="imgurl_s" :z-index="3000" v-if="imgflagmodel" :preview-src-list="srcList">
                  </el-image>
                  <el-link style="width: 50px" v-if="imgflagdel" icon="el-icon-delete" @ @click="delimg">删除</el-link>
                  <el-upload
                    class="upload"
                    v-if="imgflag"
                    :class="{ hide: hideUp, uoloadSty: showBtnImg, disUoloadSty: noneBtnImg }"
                    :action="getUploadUrl"
                    list-type="picture-card"
                    :limit="1"
                    accept="image/*"
                    name="image"
                    :on-success="handleUpSuccess"
                    :on-error="handleUpError"
                    :on-change="handleUpUpload"
                    :on-preview="handleUpPreview"
                    :on-remove="handleUpRemove"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible_saveUpload">
                    <img width="100%" :src="dialogImageUrl" alt="" />
                  </el-dialog>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-card>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="save_Upload">提 交</el-button>
        <el-button @click="handleClose_waybillManagementReceiptUpload">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="dialog_waybillMan_JustShow_tit"
      :visible.sync="dialogVisible_JustShow"
      width="85%"
      :modal="true"
      center
      :before-close="handleClose_JustShow"
    >
      <el-card class="box-card materialTableData" :body-style="{ padding: '10px' }" style="margin-top: 10px">
        <div slot="header">
          <span style="font-size: 14px">物料明细</span>
        </div>
        <div>
          <el-row>
            <el-table :data="materialTableData_JustShow" border style="width: 100%; height: 100%">
              <el-table-column label="外部单据号" width="200" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.preOrderNo }}</span>
                </template>
              </el-table-column>
              <el-table-column label="物料名称" width="300" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.materialName }}</span>
                </template>
              </el-table-column>
              <el-table-column label="件数" width="180" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.pkgQty }}</span>
                </template>
              </el-table-column>
              <el-table-column label="毛重（kg）" width="180" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.weight }}</span>
                </template>
              </el-table-column>
              <el-table-column label="体积（m³）" width="180" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.volume }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </div>
      </el-card>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible_imgUrl" :before-close="handleCloseImgDialog">
      <el-link :underline="false">{{ dialogVisible_orderNo }}</el-link>
      <el-image :src="dialogImageUrl_via" :z-index="3000" :preview-src-list="dialogImageUrl_via_srcList"> </el-image>
      <!--<img width="100%" :src="dialogImageUrl_via" alt="">-->
      <span slot="footer" v-if="dialogVisible_imgUrl_flag" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="SavereceiptPassed">通过</el-button>
        <el-button type="primary" @click="Savereceiptreject">驳回</el-button>
      </span>
    </el-dialog>
    <el-dialog title="发货方查询" :visible.sync="dialogTableVisible" width="70%" @close="close_dialog_comm_carriers">
      <el-form :inline="true" v-model="form" size="mini" label-position="center">
        <el-row>
          <el-col :span="8">
            <div class="grid-content">
              <el-form-item label="发货方编码" label-width="90px" style="width: 250px">
                <el-input size="mini" v-model="form.consignerId" style="width: 150px"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <el-form-item label="发货方名称" label-width="90px" style="width: 250px">
                <el-input size="mini" v-model="form.consignerName" style="width: 150px"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <el-form-item label="发货方地址" label-width="90px" style="width: 250px">
                <el-input size="mini" v-model="form.consignerAddress" style="width: 150px"></el-input>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="grid-content">
              <el-form-item label="发货方联系人" label-width="100px" style="width: 260px">
                <el-input size="mini" v-model="form.consignerLinkman" style="width: 140px"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="16">
            <div class="grid-content">
              <el-button type="primary" plain size="mini" @click="consignSearch">查询</el-button>
              <el-button type="primary" plain size="mini" @click="consignOkay">确定</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="waybill_add_dialog">
        <flight-table
          :tableData="contableData"
          :columns="conColumns"
          :pagesizes="pagesizes"
          :isPagination="true"
          @handleSelectionChange="handleSelectionChange"
          @clickTable="dubbleClick"
          @handleCurrentChange="handleCurrentChange_compan"
          @handleSizeChange="handleSizeChange"
          :total="pagination2.total"
          :pageSize="pagination2.pageSize"
          :current-page="pagination2.pageNum"
        ></flight-table>
      </el-row>
    </el-dialog>
    <el-dialog title="收货方查询" :visible.sync="dialogTableVisible1" width="85%" @close="closedialogTableVisible1">
      <el-form :inline="true" v-model="form1" size="mini" label-position="center">
        <el-row>
          <el-col :span="8">
            <div class="grid-content">
              <el-form-item label="收货方编码" label-width="90px" style="width: 250px">
                <el-input size="mini" v-model="form1.consumerId" style="width: 150px"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <el-form-item label="收货方名称" label-width="90px" style="width: 250px">
                <el-input size="mini" v-model="form1.consumerName" style="width: 150px"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <el-form-item label="收货方地址" label-width="90px" style="width: 250px">
                <el-input size="mini" v-model="form1.consumerAddress" style="width: 150px"></el-input>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="grid-content">
              <el-form-item label="收货方联系人" label-width="100px" style="width: 260px">
                <el-input size="mini" v-model="form1.consumerLinkman" style="width: 140px"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <el-form-item label="线路名称" label-width="90px" style="width: 250px">
                <el-input size="mini" v-model="form1.lineName" style="width: 150px"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <el-button type="primary" plain size="mini" @click="vendeeSearch">查询</el-button>
              <el-button type="primary" plain size="mini" @click="veeOkay">确定</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="waybill_add_dialog">
        <flight-table
          :tableData="veetableData"
          :columns="veeColumns"
          :pagesizes="pagesizes"
          :isPagination="true"
          @handleSelectionChange="handleSelectionChange1"
          @clickTable="dubbleClick1"
          @handleCurrentChange="handleCurrentChange_compan1"
          @handleSizeChange="handleSizeChange1"
          :total="pagination1.total"
          :pageSize="pagination1.pageSize"
          :current-page="pagination1.pageNum"
        ></flight-table>
      </el-row>
    </el-dialog>
    <ele-import
      :append="append"
      :fields="fields"
      :filepath="filepath"
      :formatter="formatter"
      :requestFn="requestFn"
      :rules="rules"
      :tips="tips"
      :title="title"
      :visible.sync="visible"
      @close="handleCloseImport"
      @finish="handleFinishImport"
    />
  </div>
</template>

<script>
//引入组件
import SearchForm from '../../components/common/form.vue'
import cesTable from '../../components/common/table.vue'
import Drawer from '../../components/common/drawer.vue'
import arrayQuery from '../../components/arrayQuery.js'
import axios from 'axios'
import $ from 'jquery'
import qs from 'qs'
import 'element-ui/lib/theme-chalk/index.css'
import flightTable from '../../components/common/flightTable.vue'
import CityLinkageReg from '../../components/common/cityLinkageReg.vue'
import BuyerListCityLinkage from '../../components/common/BuyerAddressInformation.vue'
import SellerListCityLinkage from '../../components/common/SellerAddressInformation.vue'
export default {
  data() {
    let status = [
      { label: '初始', value: '0' },
      { label: '已确认', value: '2' },
      { label: '已撤单', value: '4' },
    ]
    let statusProps = { label: 'label', value: 'value' }
    return {
      dialogTableVisible: false,
      dialogTableVisible1: false,
      form: {
        consignerId: '',
        consignerName: '',
        consignerAddress: '',
        consignerLinkman: '',
      },
      form1: {
        consumerId: '',
        consumerName: '',
        consumerAddress: '',
        consumerLinkman: '',
        lineName: '',
      },
      pagination2: {
        pageSize: 5,
        pageNum: 1,
        total: 0,
      },
      pagination1: {
        pageSize: 5,
        pageNum: 1,
        total: 0,
      },
      conColumns: [
        {
          id: 'selection',
          type: 'selection',
          label: '',
          fixed: 'left',
          width: '55',
          prop: '',
          isShow: true,
          align: 'center',
        },
        {
          id: 'consignerId',
          type: '',
          label: '发货方编码',
          fixed: false,
          width: '120',
          prop: 'consignerId',
          isShow: true,
          align: 'center',
        },
        {
          id: 'consignerName',
          type: '',
          label: '发货方名称',
          fixed: false,
          width: '180',
          prop: 'consignerName',
          isShow: true,
          align: 'center',
        },
        {
          id: 'consignerAddress',
          type: '',
          label: '发货方地址',
          fixed: false,
          width: '180',
          prop: 'consignerAddress',
          isShow: true,
          align: 'center',
        },
        {
          id: 'consignerLinkman',
          type: '',
          label: '发货方联系人',
          fixed: false,
          width: '180',
          prop: 'consignerLinkman',
          isShow: true,
          align: 'center',
        },
        {
          id: 'consignerMobile',
          type: '',
          label: '发货方手机号',
          fixed: false,
          width: '180',
          prop: 'consignerMobile',
          isShow: true,
          align: 'center',
        },
        {
          id: 'provinceCode',
          type: '',
          label: '省',
          fixed: false,
          width: '180',
          prop: 'provinceCode',
          isShow: true,
          align: 'center',
        },
        {
          id: 'cityCode',
          type: '',
          label: '市',
          fixed: false,
          width: '180',
          prop: 'cityCode',
          isShow: true,
          align: 'center',
        },
        {
          id: 'districtCode',
          type: '',
          label: '区',
          fixed: false,
          width: '180',
          prop: 'districtCode',
          isShow: true,
          align: 'center',
        },
      ],
      veeColumns: [
        {
          id: 'selection',
          type: 'selection',
          label: '',
          fixed: 'left',
          width: '55',
          prop: '',
          isShow: true,
          align: 'center',
        },
        {
          id: 'lineName',
          type: '',
          label: '线路名称',
          fixed: false,
          width: '140',
          prop: 'lineName',
          isShow: true,
          align: 'center',
        },
        {
          id: 'lineType',
          type: '',
          label: '线路类型',
          fixed: false,
          width: '100',
          prop: 'lineType',
          isShow: true,
          align: 'center',
          formatter: row => {
            if (row.lineType == 0) {
              return '市内'
            } else if (row.lineType == 1) {
              return '外阜'
            } else {
              return ''
            }
          },
        },
        {
          id: 'timelinessType',
          type: '',
          label: '线路时效类型',
          fixed: false,
          width: '120',
          prop: 'timelinessType',
          isShow: true,
          align: 'center',
          formatter: row => {
            if (row.timelinessType == 1) {
              return '固定值'
            } else if (row.timelinessType == 2) {
              return '小时'
            } else if (row.timelinessType == 3) {
              return '某天某时刻'
            } else if (row.timelinessType == 4) {
              return '某天某时某分'
            }
          },
        },
        {
          id: 'timeliness',
          type: '',
          label: '线路时效值',
          fixed: false,
          width: '100',
          prop: 'timeliness',
          isShow: true,
          align: 'center',
        },
        {
          id: 'consumerId',
          type: '',
          label: '收货方编码',
          fixed: false,
          width: '100',
          prop: 'consumerId',
          isShow: true,
          align: 'center',
        },
        {
          id: 'consumerName',
          type: '',
          label: '收货方名称',
          fixed: false,
          width: '180',
          prop: 'consumerName',
          isShow: true,
          align: 'center',
        },
        {
          id: 'consumerAddress',
          type: '',
          label: '收货方地址',
          fixed: false,
          width: '180',
          prop: 'consumerAddress',
          isShow: true,
          align: 'center',
        },
        {
          id: 'consumerLinkman',
          type: '',
          label: '收货方联系人',
          fixed: false,
          width: '110',
          prop: 'consumerLinkman',
          isShow: true,
          align: 'center',
        },
        {
          id: 'consumerMobile',
          type: '',
          label: '收货方手机号',
          fixed: false,
          width: '150',
          prop: 'consumerMobile',
          isShow: true,
          align: 'center',
        },
        {
          id: 'consumerTelephone',
          type: '',
          label: '收货方固话',
          fixed: false,
          width: '180',
          prop: 'consumerTelephone',
          isShow: true,
          align: 'center',
        },
        {
          id: 'provinceCode',
          type: '',
          label: '省',
          fixed: false,
          width: '120',
          prop: 'provinceCode',
          isShow: true,
          align: 'center',
        },
        {
          id: 'cityCode',
          type: '',
          label: '市',
          fixed: false,
          width: '120',
          prop: 'cityCode',
          isShow: true,
          align: 'center',
        },
        {
          id: 'districtCode',
          type: '',
          label: '区',
          fixed: false,
          width: '120',
          prop: 'districtCode',
          isShow: true,
          align: 'center',
        },
      ],
      contableData: [],
      veetableData: [],
      conData: [],
      veeData: [],
      title: '物料导入',
      tips: ['物料名称必填', '件毛体任意一项必须大于0'],
      fields: {
        materialName: '物料名称',
        pkgQty: '件数',
        weight: '毛重',
        volume: '体积',
      },
      append: {
        //这里是附加数据，导入数据时要加上iseditor，让用户可以启动编辑
        iseditor: true,
      },
      // 短信
      noteVisible: false,
      delivery: 1,
      receive: 1,
      formatter: {},
      rules: {
        materialName: { required: true, message: '物料名称必填' },
      },
      filepath: 'https://docs.qq.com/sheet/DUUZEQmpLeW1tUnhu/物料导入模板.xlsx', //导入模板的图片文件夹地址
      visible: false,
      editFlag: false,
      saveFlag: false,
      table: false,
      dialog: false,
      loading: false,
      dialogVisible_waybilladd: false,
      dialog_waybillMan_Title_Flag: '',
      dialogVisible_orderNo: '',
      dialog_comm_carriers: false,
      dialogVisible_imgUrl: false,
      dialogImageUrl_via: '',
      saveAndAddFlag: false,
      dialog_waybillManagementReceiptUpload: false,
      hideUp: false,
      dialogImageUrl: '',
      dialogVisible_saveUpload: false,
      showBtnImg: true,
      noneBtnImg: false,
      dialog_waybillMan_JustShow_tit: '',
      dialogVisible_JustShow: false,
      dialogVisible_imgUrl_flag: false,
      limitCountImg: 1,
      carriers_form: {
        carriersId: '',
        carriersName: '',
      },
      tableDatadialog: [],
      materialTableData: [],
      materialTableData_JustShow: [],
      columns: [
        {
          id: 'selection',
          type: 'selection',
          label: '',
          fixed: 'left',
          width: '55',
          prop: '',
          isShow: true,
          align: 'center',
        },
        {
          id: 'carriersId',
          type: '',
          label: '编码',
          fixed: false,
          width: '280',
          prop: 'carriersId',
          isShow: true,
          align: 'center',
        },
        {
          id: 'carriersName',
          type: '',
          label: '名称',
          fixed: false,
          width: '280',
          prop: 'carriersName',
          isShow: true,
          align: 'center',
        },
      ],
      tableDatadialog_pagination: {
        pageSize: 5,
        pageNum: 1,
        total: 0,
      },
      pagesizes: [5, 10, 15, 20],
      waybillDoFormrules: {
        // province: [
        //   { required: true, message: '请选择省', trigger: ['blur', 'change'] }
        // ],
        consignId: [{ required: true, message: '请选择发货方', trigger: ['blur', 'change'] }],
        consignName: [{ required: true, message: '请输入发货方名称', trigger: ['blur', 'change'] }],
        consignAddress: [{ required: true, message: '发货方地址必填', trigger: 'blur' }],
        consignLinkman: [{ required: true, message: '发货方联系人必填', trigger: 'blur' }],
        consignMobile: [
          { required: true, message: '发货方手机号必填', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入11位手机号码', trigger: 'blur' },
          {
            pattern: '^1[3456789]\\d{9}$',
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
        remark: [{ max: 50, message: '最多输入50字符' }],
        vendeeName: [{ required: true, message: '请输入收货方名称', trigger: ['blur', 'change'] }],
        vendeeAddress: [{ required: true, message: '收货方地址必填', trigger: 'blur' }],
        vendeeLinkman: [{ required: true, message: '收货方联系人必填', trigger: 'blur' }],
        // vendeePhone: [
        //   { required: true, message: '收货方固话必填', trigger: 'blur' }
        // ],
        vendeeLine: [{ required: true, message: '线路名称必填', trigger: 'blur' }],
        outGoing: [{ required: true, message: '线路类型必填', trigger: 'blur' }],
        timelinessType: [{ required: true, message: '线路时效类型必填', trigger: 'blur' }],
        timeliness: [{ required: true, message: '线路时效必填', trigger: 'blur' }],
        vendeeMobile: [
          { required: true, message: '收货方手机号必填', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入11位手机号码', trigger: 'blur' },
          {
            pattern: '^1[3456789]\\d{9}$',
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        },
      },
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      saveReceipt: {
        orderNo: '',
        orderReceiptUrl: '',
      },
      imgurl_s: '',
      imgflag: true,
      imgflagmodel: false,
      srcList: [],
      dialogImageUrl_via_srcList: [],
      imgflagdel: false,
      AccurateAndUnique_orderNo: '',
      dataForms: {
        orderNo: '',
        status: '',
        vendeeLine: '',
        batchNo: '',
        transStatus: '',
        companyName: '',
        vendeeName: '',
        consignName: '',
        vendeeLinkman: '',
        carrierId: '',
        driver: '',
        truckNo: '',
        chintBusiness: '',
        receiptStatus: '',
        outGoing: '',
        createTimeBegin: '',
        createTimeEnd: '',
      },
      waybillDoForm: {
        orderNo: '',
        consignId: '',
        consignName: '',
        consignAddress: '',
        consignLinkman: '',
        consignPhone: '',
        consignMobile: '',
        vendeeName: '',
        vendeeAddress: '',
        vendeeLinkman: '',
        vendeePhone: '',
        vendeeMobile: '',
        replyDeliveryTime: '',
        requiredDeliveryTime: '',
        remark: '',
        pkgQty: '',
        weight: '',
        volume: '',
        wunit: '',
        vunit: '',
        timelinessType: '',
        timeliness: '',
        vendeeLine: '',
        vendeeProvince: '',
        vendeeProvinceName: '',
        vendeeCity: '',
        vendeeCityName: '',
        vendeeDistrict: '',
        vendeeDistrictName: '',
        consignProvince: '',
        consignProvinceName: '',
        consignCity: '',
        consignCityName: '',
        consignDistrict: '',
        consignDistrictName: '',
        chintBusiness: '0',
        outGoing: '',
      },
      formLabelWidth: '80px',
      timer: null,
      searchData: {
        //查询表单的对应的值
        orderNo: '',
      },
      apiId: 'null',
      fieldList: [],
      // 表格
      tableData: [], //表格数据
      tableCols: [
        //表格列数据
        //{label:'运单编号',prop:'orderNo',width:'160',align:'center'},
        {
          label: '创建时间',
          prop: 'createTime',
          width: '150',
          align: 'center',
          sortable: true,
          formatter: (row, column) => {
            if (row.createTime == null) {
              return ''
            } else {
              return this.$moment(row.createTime).format('YYYY-MM-DD HH:mm:ss')
            }
          },
        },
        { label: '运单编号', type: 'imgLinkProNoder', prop: 'orderNo', align: 'center', width: '170' },
        {
          label: '单据状态',
          prop: 'typeId',
          width: '70',
          align: 'center',
          formatter: row => {
            if (row.status == 0) {
              return '正常'
            } else if (row.status == 1) {
              return '撤单'
            } else {
              return ''
            }
          },
        },
        {
          label: '物流状态',
          prop: 'transStatus',
          width: '70',
          align: 'center',
          formatter: row => {
            if (row.transStatus == 0) {
              return '初始'
            } else if (row.transStatus == 2) {
              return '已发布'
            } else if (row.transStatus == 4) {
              return '已揽收'
            } else if (row.transStatus == 6) {
              return '派送中'
            } else if (row.transStatus == 8) {
              return '已送达'
            } else if (row.transStatus == 10) {
              return '已签收'
            } else {
              return ''
            }
          },
        },
        {
          label: '派车状态',
          prop: 'assignStatus',
          width: '100',
          align: 'center',
          sortable: true,
          formatter: row => {
            if (row.assignStatus == 0) {
              return '未派'
            } else if (row.assignStatus == 1) {
              return '已派'
            } else {
              return ''
            }
          },
        },
        {
          label: '线路',
          prop: 'vendeeLine',
          align: 'center',
          width: '80',
        },
        {
          label: '线路时效类型',
          prop: 'timelinessType',
          align: 'center',
          width: '100',
          formatter: row => {
            if (row.timelinessType == 1) {
              return '固定值'
            } else if (row.timelinessType == 2) {
              return '小时'
            } else if (row.timelinessType == 3) {
              return '某天某时刻'
            } else if (row.timelinessType == 4) {
              return '某天某时某分'
            }
          },
        },
        { label: '线路时效值', prop: 'timeliness', align: 'center', width: '90' },
        { label: '总件数', prop: 'pkgQty', align: 'center', width: '80' },
        { label: '总重量（kg）', prop: 'weight', align: 'center', width: '100' },
        {
          label: '总体积（m³）',
          prop: 'volume',
          align: 'center',
          width: '100',
          formatter: (row, column) => {
            return this.getFloat(row.volume, 4)
          },
        },
        { label: '收货方名称', prop: 'vendeeName', align: 'center', width: '180', sortable: true },
        { label: '收货方联系人', prop: 'vendeeLinkman', align: 'center', width: '120', sortable: true },
        { label: '收货方电话', prop: 'vendeeMobile', align: 'center', width: '120' },
        {
          label: '收货方省市区信息',
          prop: 'vendeeProvinceName',
          align: 'center',
          width: '160',
          formatter: row => {
            let vendeeProvinceName = ''
            let vendeeCityName = ''
            let vendeeDistrictName = ''
            if (row.vendeeProvinceName !== null) {
              vendeeProvinceName = row.vendeeProvinceName
            } else {
              vendeeProvinceName = ''
            }
            if (row.vendeeCityName !== null) {
              vendeeCityName = row.vendeeCityName
            } else {
              vendeeCityName = ''
            }
            if (row.vendeeDistrictName !== null) {
              vendeeDistrictName = row.vendeeDistrictName
            } else {
              vendeeDistrictName = ''
            }
            let aa = vendeeProvinceName + vendeeCityName + vendeeDistrictName
            return aa
          },
        },
        { label: '收货方地址', prop: 'vendeeAddress', align: 'center', width: '400' },
        { label: '发货方名称', prop: 'consignName', align: 'center', width: '220' },
        { label: '发货方联系人', prop: 'consignLinkman', align: 'center', width: '120' },
        { label: '发货方电话', prop: 'consignMobile', align: 'center', width: '120' },
        {
          label: '发货方省市区信息',
          prop: 'consignProvinceName',
          align: 'center',
          width: '160',
          formatter: row => {
            let consignProvinceName = ''
            let consignCityName = ''
            let consignDistrictName = ''
            if (row.consignProvinceName !== null) {
              consignProvinceName = row.consignProvinceName
            } else {
              consignProvinceName = ''
            }
            if (row.consignCityName !== null) {
              consignCityName = row.consignCityName
            } else {
              consignCityName = ''
            }
            if (row.consignDistrictName !== null) {
              consignDistrictName = row.consignDistrictName
            } else {
              consignDistrictName = ''
            }
            let aa = consignProvinceName + consignCityName + consignDistrictName
            return aa
          },
        },
        { label: '发货方地址', prop: 'consignAddress', align: 'center', width: '400' },
        { label: '承运商编码', prop: 'carriersId', align: 'center', width: '100' },
        { label: '承运商名称', prop: 'carriersName', align: 'center', width: '180' },
        { label: '司机名称', prop: 'driverName', align: 'center', width: '100' },
        { label: '车牌号', prop: 'truckNo', align: 'center', width: '100' },
        { label: '批次号', prop: 'batchNo', align: 'center', width: '180' },
        {
          label: '开始派送时间',
          prop: 'sendStartTime',
          width: '160',
          align: 'center',
          formatter: (row, column) => {
            if (row.sendStartTime == null) {
              return ''
            } else {
              return this.$moment(row.sendStartTime).format('YYYY-MM-DD HH:mm:ss')
            }
          },
        },
        {
          label: '送达时间',
          prop: 'deliveryTime',
          width: '160',
          align: 'center',
          formatter: (row, column) => {
            if (row.deliveryTime == null) {
              return ''
            } else {
              return this.$moment(row.deliveryTime).format('YYYY-MM-DD HH:mm:ss')
            }
          },
        },
        {
          label: '签收时间',
          prop: 'signTime',
          width: '160',
          align: 'center',
          formatter: (row, column) => {
            if (row.signTime == null) {
              return ''
            } else {
              return this.$moment(row.signTime).format('YYYY-MM-DD HH:mm:ss')
            }
          },
        },
        {
          label: '客户要求到货时间',
          prop: 'requiredDeliveryTime',
          width: '160',
          align: 'center',
          formatter: (row, column) => {
            if (row.requiredDeliveryTime == null) {
              return ''
            } else {
              return this.$moment(row.requiredDeliveryTime).format('YYYY-MM-DD HH:mm:ss')
            }
          },
        },
        {
          label: '平台承诺到货时间',
          prop: 'replyDeliveryTime',
          width: '160',
          align: 'center',
          formatter: (row, column) => {
            if (row.replyDeliveryTime == null) {
              return ''
            } else {
              return this.$moment(row.replyDeliveryTime).format('YYYY-MM-DD HH:mm:ss')
            }
          },
        },
        { label: '收货方名称', prop: 'vendeeName', align: 'center', width: '120' },
        { label: '收货方联系人', prop: 'vendeeLinkman', align: 'center', width: '120' },
        {
          label: '回单状态',
          prop: 'receiptStatus',
          align: 'center',
          width: '100',
          formatter: row => {
            if (row.receiptStatus == 0) {
              return '未上传回单'
            } else if (row.receiptStatus == 1) {
              return '上传未确认'
            } else if (row.receiptStatus == 2) {
              return '确认不通过'
            } else if (row.receiptStatus == 3) {
              return '确认通过'
            } else {
              return ''
            }
          },
        },
        {
          label: '回单附件',
          type: 'imgLink',
          prop: 'orderReceiptUrl',
          orderNo: 'orderNo',
          align: 'center',
          width: '80',
          formatter: row => {
            if (row.orderReceiptUrl == '' || row.orderReceiptUrl == null || row.orderReceiptUrl == 'null') {
              return ''
            } else {
              return '查看附件'
            }
          },
        },
        {
          label: '派送短信',
          prop: 'dispatchMsgFlag',
          align: 'center',
          width: '80',
          formatter: row => {
            if (row.dispatchMsgFlag == 1) {
              return '发送'
            } else if (row.dispatchMsgFlag == 0) {
              return '不发送'
            } else {
              return ''
            }
          },
        },
        {
          label: '送达短信',
          prop: 'arriveMsgFlag',
          align: 'center',
          width: '80',
          formatter: row => {
            if (row.arriveMsgFlag == 1) {
              return '发送'
            } else if (row.arriveMsgFlag == 0) {
              return '不发送'
            } else {
              return ''
            }
          },
        },
        {
          label: '正泰业务',
          prop: 'chintBusiness',
          align: 'center',
          width: '80',
          formatter: row => {
            if (row.chintBusiness == 1) {
              return '否'
            } else if (row.chintBusiness == 0) {
              return '是'
            } else {
              return ''
            }
          },
        },
        {
          label: '是否外阜',
          prop: 'outGoing',
          align: 'center',
          width: '100',
          formatter: row => {
            if (row.outGoing == 0) {
              return '市内'
            } else if (row.outGoing == 1) {
              return '外阜'
            } else {
              return ''
            }
          },
        },
        { label: '单据来源', prop: 'orderSource', align: 'center', width: '120' },
        { label: 'EC客户名称', prop: 'vendeeGroupName', align: 'center', width: '120' },
        { label: '创建人', prop: 'creator', align: 'center', width: '130' },
      ],
      tableHandles: [
        //这是表格和表单之间的一个按钮，我的项目不需要
        { label: '新增', type: 'primary', handle: row => '' },
      ],
      pagination: {
        //分页数据
        pageSize: 50,
        pageNum: 1,
        total: 0,
      },
      currentPageButtons: [],
      merge: [],
      waybillSearchFlag: true,
      waybillSearchFlagA: true,
      waybillModifyFlag: true,
      waybillManagementAddFlag: true,
      waybillReleaseFlag: true,
      waybillwaybillPublishFallbackFlag: true,
      waybillServiceFlag: true,
      waybillCancelFlag: true,
      waybillCollectFlag: true,
      waybillCollectBackFlag: true,
      waybillManagementReceiptUploadFlag: true,
      waybillreceiptConfirmationFlag: true,
      exportWayBillTime: true,
      waybillExportFlag: true,
      consignmentFlag: true,
      handoverFlag: true,
      immovableFlag: true,
      downloadName: '',
      addList: {
        province: '',
        city: '',
        area: '',
      },
      BuyerList: {
        province: '',
        provinceName: '',
        city: '',
        cityName: '',
        area: '',
        areaName: '',
      },
      SellerList: {
        province: '',
        provinceName: '',
        city: '',
        cityName: '',
        area: '',
        areaName: '',
      },
    }
  },

  components: {
    //引入组件后注册组件
    cesTable,
    Drawer,
    flightTable,
    CityLinkageReg,
    BuyerListCityLinkage,
    SellerListCityLinkage,
  },
  computed: {
    getUploadUrl() {
      let filePath = `${this.$filePath}`
      return filePath
    },
  },
  mounted() {
    this.getTableAndForm()
    let parentId = '2_2_2'
    let currentPageButtons = arrayQuery.text(parentId)
    // console.log(currentPageButtons);
    let _this = this
    _this.currentPageButtons = currentPageButtons
    //查询按钮权限
    let waybillSearch = currentPageButtons.find(item => {
      return item.menuId == '2_2_2_1'
    })
    if (waybillSearch == undefined) {
      _this.waybillSearchFlag = false
    } else {
      _this.waybillSearchFlag = true
    }
    //高级查询刷新按钮权限
    let waybillSearchA = currentPageButtons.find(item => {
      return item.menuId == '2_2_2_13'
    })
    if (waybillSearchA == undefined) {
      _this.waybillSearchFlagA = false
    } else {
      _this.waybillSearchFlagA = true
    }
    //新增按钮
    let waybillManagementAdd = currentPageButtons.find(item => {
      return item.menuId == '2_2_2_2'
    })
    if (waybillManagementAdd == undefined) {
      _this.waybillManagementAddFlag = false
    } else {
      _this.waybillManagementAddFlag = true
    }
    //修改按钮权限
    let waybillModify = currentPageButtons.find(item => {
      return item.menuId == '2_2_2_3'
    })
    if (waybillModify == undefined) {
      _this.waybillModifyFlag = false
    } else {
      _this.waybillModifyFlag = true
    }
    //发布按钮权限
    let waybillRelease = currentPageButtons.find(item => {
      return item.menuId == '2_2_2_4'
    })
    if (waybillRelease == undefined) {
      _this.waybillReleaseFlag = false
    } else {
      _this.waybillReleaseFlag = true
    }
    //发布回退
    let waybillwaybillPublishFallback = currentPageButtons.find(item => {
      return item.menuId == '2_2_2_5'
    })
    if (waybillwaybillPublishFallback == undefined) {
      _this.waybillwaybillPublishFallbackFlag = false
    } else {
      _this.waybillwaybillPublishFallbackFlag = true
    }
    //送达按钮权限
    let waybillService = currentPageButtons.find(item => {
      return item.menuId == '2_2_2_6'
    })
    if (waybillService == undefined) {
      _this.waybillServiceFlag = false
    } else {
      _this.waybillServiceFlag = true
    }
    //撤单按钮权限
    let waybillCancel = currentPageButtons.find(item => {
      return item.menuId == '2_2_2_7'
    })
    if (waybillCancel == undefined) {
      _this.waybillCancelFlag = false
    } else {
      _this.waybillCancelFlag = true
    }
    //揽收按钮权限
    let waybillCollect = currentPageButtons.find(item => {
      return item.menuId == '2_2_2_8'
    })
    if (waybillCollect == undefined) {
      crea
      _this.waybillCollectFlag = false
    } else {
      _this.waybillCollectFlag = true
    }
    //揽收回退按钮权限
    let waybillCollectBack = currentPageButtons.find(item => {
      return item.menuId == '2_2_2_9'
    })
    if (waybillCollectBack == undefined) {
      _this.waybillCollectBackFlag = false
    } else {
      _this.waybillCollectBackFlag = true
    }
    //回单上传
    let waybillManagementReceiptUpload = currentPageButtons.find(item => {
      return item.menuId == '2_2_2_10'
    })
    if (waybillManagementReceiptUpload == undefined) {
      _this.waybillManagementReceiptUploadFlag = false
    } else {
      _this.waybillManagementReceiptUploadFlag = true
    }
    //回单确认
    let waybillreceiptConfirmation = currentPageButtons.find(item => {
      return item.menuId == '2_2_2_11'
    })
    if (waybillreceiptConfirmation == undefined) {
      _this.waybillreceiptConfirmationFlag = false
    } else {
      _this.waybillreceiptConfirmationFlag = true
    }

    //导出
    let waybillExport = currentPageButtons.find(item => {
      return item.menuId == '2_2_2_12'
    })
    if (waybillExport == undefined) {
      _this.waybillExportFlag = false
    } else {
      _this.waybillExportFlag = true
    }
    //  按时间筛选导出运单时效导出
    let exportWayBillTimeExport = currentPageButtons.find(item => {
      return item.menuId == '2_2_2_18'
    })
    if (exportWayBillTimeExport == undefined) {
      _this.exportWayBillTime = false
    } else {
      _this.exportWayBillTime = true
    }

    //A5打印
    let consignment = currentPageButtons.find(item => {
      return item.menuId === '2_2_2_13'
    })
    if (consignment === undefined) {
      _this.consignmentFlag = false
    } else {
      _this.consignmentFlag = true
    }

    //五联
    let handover = currentPageButtons.find(item => {
      return item.menuId === '2_2_2_14'
    })
    if (handover === undefined) {
      _this.handoverFlag = false
    } else {
      _this.handoverFlag = true
    }

    //交接单
    let immovable = currentPageButtons.find(item => {
      return item.menuId === '2_2_2_15'
    })
    if (immovable === undefined) {
      _this.immovableFlag = false
    } else {
      _this.immovableFlag = true
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pagination2.pageSize = val
      this.consignSearch()
    },
    handleSizeChange1(val) {
      console.log(12312312)
      this.pagination1.pageSize = val
      this.vendeeSearch()
    },
    handleCurrentChange_compan(val) {
      console.log(12321312)
      this.pagination2.pageNum = val
      this.consignSearch()
    },
    handleCurrentChange_compan1(val) {
      this.pagination1.pageNum = val
      this.vendeeSearch()
    },
    consignFocus() {
      this.consignSearch()
      this.dialogTableVisible = true
    },
    vendeeFocus() {
      this.vendeeSearch()
      this.dialogTableVisible1 = true
    },
    consignSearch() {
      let params = {
        consignerId: this.form.consignerId,
        consignerName: this.form.consignerName,
        consignerAddress: this.form.consignerAddress,
        consignerLinkman: this.form.consignerLinkman,
        pageSize: this.pagination2.pageSize,
        pageNo: this.pagination2.pageNum,
      }
      $.ajax({
        type: 'post',
        dataType: 'json',
        contentType: 'application/json',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Authorization',
          Authorization: this.$store.state.token,
        },
        //设置允许ajax请求鞋带cookie即可
        xhrFields: {
          withCredentials: true,
        },
        async: true,
        url: `${this.$apiPath}/api/urban/consigner/queryConsignerInfosUtilPage`,
        data: JSON.stringify(params),
        beforeSend: request => {
          request.setRequestHeader('Authorization', this.$store.state.token)
          // request.setRequestHeader("token", this.$store.state.token);
        },
        success: res => {
          if (res.success == true) {
            this.contableData = res.data.items
            console.log(this.contableData)

            this.pagination2.total = res.data.total
          } else {
            this.$alert(res.msg, '提示', {
              confirmButtonText: '确定',
            })
          }
        },
      })
    },
    vendeeSearch() {
      let params = {
        consumerId: this.form1.consumerId,
        consumerName: this.form1.consumerName,
        consumerAddress: this.form1.consumerAddress,
        consumerLinkman: this.form1.consumerLinkman,
        lineName: this.form1.lineName,
        pageSize: this.pagination1.pageSize,
        pageNo: this.pagination1.pageNum,
      }
      $.ajax({
        type: 'post',
        dataType: 'json',
        contentType: 'application/json',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Authorization',
          Authorization: this.$store.state.token,
        },
        //设置允许ajax请求鞋带cookie即可
        xhrFields: {
          withCredentials: true,
        },
        async: true,
        url: `${this.$apiPath}/api/urban/consumer/queryConsumerInfosUtilPage`,
        data: JSON.stringify(params),
        beforeSend: request => {
          request.setRequestHeader('Authorization', this.$store.state.token)
          // request.setRequestHeader("token", this.$store.state.token);
        },
        success: res => {
          if (res.success == true) {
            this.veetableData = res.data.items
            console.log(this.veetableData)

            this.pagination1.total = res.data.total
          } else {
            this.$alert(res.msg, '提示', {
              confirmButtonText: '确定',
            })
          }
        },
      })
    },
    // 双击事件的行触发
    dubbleClick(val) {
      console.log(val)
      this.waybillDoForm.consignId = val.row.consignerId
      this.waybillDoForm.consignName = val.row.consignerName
      this.waybillDoForm.consignAddress = val.row.consignerAddress
      this.waybillDoForm.consignLinkman = val.row.consignerLinkman
      this.waybillDoForm.consignMobile = val.row.consignerMobile
      this.waybillDoForm.remark = val.row.remarker
      this.SellerList.province = val.row.provinceCode
      this.SellerList.provinceName = val.row.provinceCode
      this.SellerList.city = val.row.cityCode
      this.SellerList.cityName = val.row.cityCode
      this.SellerList.area = val.row.districtCode
      this.SellerList.areaName = val.row.districtCode
      this.dialogTableVisible = false
    },
    // 双击事件的行触发
    dubbleClick1(val) {
      this.waybillDoForm.vendeeName = val.row.consumerName
      this.waybillDoForm.outGoing = val.row.lineType
      this.waybillDoForm.vendeeLine = val.row.lineName
      this.waybillDoForm.timeliness = val.row.timeliness
      this.waybillDoForm.timelinessType = val.row.timelinessType
      this.waybillDoForm.vendeeMobile = val.row.consumerMobile
      this.waybillDoForm.vendeePhone = val.row.consumerTelephone
      this.waybillDoForm.vendeeLinkman = val.row.consumerLinkman
      this.waybillDoForm.vendeeAddress = val.row.consumerAddress
      this.BuyerList.province = val.row.provinceCode
      this.BuyerList.provinceName = val.row.provinceCode
      this.BuyerList.city = val.row.cityCode
      this.BuyerList.cityName = val.row.cityCode
      this.BuyerList.area = val.row.districtCode
      this.BuyerList.areaName = val.row.districtCode
      this.dialogTableVisible1 = false
    },
    consignOkay() {
      if (this.conData.length == 0) {
        this.$message({
          message: '请先勾选一条发货方信息',
          type: 'warning',
        })
      } else if (this.conData.length > 1) {
        this.$message({
          message: '仅允许勾选一条发货方信息',
          type: 'warning',
        })
      } else {
        this.waybillDoForm.consignId = val.row.consignerId
        this.waybillDoForm.consignName = val.row.consignerName
        this.waybillDoForm.consignAddress = val.row.consignerAddress
        this.waybillDoForm.consignLinkman = val.row.consignerLinkman
        this.waybillDoForm.consignMobile = val.row.consignerMobile
        this.waybillDoForm.remark = val.row.remarker
        this.SellerList.province = val.row.provinceCode
        this.SellerList.provinceName = val.row.provinceCode
        this.SellerList.city = val.row.cityCode
        this.SellerList.cityName = val.row.cityCode
        this.SellerList.area = val.row.districtCode
        this.SellerList.areaName = val.row.districtCode
        this.$refs.waybillDoForm.clearValidate()
        this.dialogTableVisible = false
      }
    },
    veeOkay() {
      if (this.veeData.length == 0) {
        this.$message({
          message: '请先勾选一条收货方信息',
          type: 'warning',
        })
      } else if (this.veeData.length > 1) {
        this.$message({
          message: '仅允许勾选一条收货方信息',
          type: 'warning',
        })
      } else {
        this.waybillDoForm.vendeeName = val.row.consumerName
        this.waybillDoForm.outGoing = val.row.lineType
        this.waybillDoForm.vendeeLine = val.row.lineName
        this.waybillDoForm.timeliness = val.row.timeliness
        this.waybillDoForm.timelinessType = val.row.timelinessType
        this.waybillDoForm.vendeeMobile = val.row.consumerMobile
        this.waybillDoForm.vendeePhone = val.row.consumerTelephone
        this.waybillDoForm.vendeeLinkman = val.row.consumerLinkman
        this.waybillDoForm.vendeeAddress = val.row.consumerAddress
        this.BuyerList.province = val.row.provinceCode
        this.BuyerList.provinceName = val.row.provinceCode
        this.BuyerList.city = val.row.cityCode
        this.BuyerList.cityName = val.row.cityCode
        this.BuyerList.area = val.row.districtCode
        this.BuyerList.areaName = val.row.districtCode
        this.$refs.waybillDoForm.clearValidate()
        this.dialogTableVisible1 = false
      }
    },
    //获取数据
    getCity(data) {
      this.addList.province = data.province
      this.addList.city = data.city
      this.addList.area = data.area
    },
    getBuyerListCityLinkageCity(data) {
      this.BuyerList.province = data.province
      this.BuyerList.provinceName = data.provinceName
      this.BuyerList.city = data.city
      this.BuyerList.cityName = data.cityName
      this.BuyerList.area = data.area
      this.BuyerList.areaName = data.areaName
      this.waybillDoForm.vendeeProvince = data.province
      this.waybillDoForm.vendeeProvinceName = data.provinceName
      this.waybillDoForm.vendeeCity = data.city
      this.waybillDoForm.vendeeCityName = data.cityName
      this.waybillDoForm.vendeeDistrict = data.area
      this.waybillDoForm.vendeeDistrictName = data.areaName
    },
    getSellerListCityLinkageCity(data) {
      this.SellerList.province = data.province
      this.SellerList.provinceName = data.provinceName
      this.SellerList.city = data.city
      this.SellerList.cityName = data.cityName
      this.SellerList.area = data.area
      this.SellerList.areaName = data.areaName
      this.waybillDoForm.consignProvince = data.province
      this.waybillDoForm.consignProvinceName = data.provinceName
      this.waybillDoForm.consignCity = data.city
      this.waybillDoForm.consignCityName = data.cityName
      this.waybillDoForm.consignDistrict = data.area
      this.waybillDoForm.consignDistrictName = data.areaName
    },
    onInput0_100(e) {
      this.$message.closeAll()
      // 验证是否是纯数字
      let isNumber = /^\d*$/.test(e.target.value)
      // 过滤非数字
      e.target.value = e.target.value.replace(/\D/g, '')
      if (!isNumber || e.target.value < 0 || e.target.value > 100000) {
        this.$message.warning('只能输入[0,100000]区间的整数')
      }
      e.target.value = (e.target.value >= 0 && e.target.value <= 100000 && e.target.value.match(/^\d*/g)[0]) || null
    },
    onInput0_200(e) {
      this.$message.closeAll()
      if (e.target.value < 0 || e.target.value > 100000) {
        this.$message.warning('只能输入[0,100000]区间的数')
      }
      e.target.value = (e.target.value >= 0 && e.target.value <= 100000 && e.target.value.match(/^\d{1,3}(\.\d*)?$/)[0]) || null
    },
    handleClose_JustShow() {
      this.dialogVisible_JustShow = false
      this.materialTableData_JustShow = []
    },
    //---------------------------------------回单副本--------------------------------
    handleUpSuccess(response, file, fileList) {
      this.dialogImageUrl = `${this.$imageiPPath}` + response.data
      this.saveReceipt.orderReceiptUrl = response.data
      this.imagesUp = response.data
      this.hideUp = fileList.length == 1
      this.$message.success(response.msg)
      this.imgflag = false
      this.imgflagmodel = true
      this.imgflagdel = true
      this.imgurl_s = `${this.$imageiPPath}` + response.data
      this.srcList.push(`${this.$imageiPPath}` + response.data)
    },
    handleUpError(err, file, fileList) {},
    // 删除图片
    handleUpRemove(file, fileList) {
      this.imagesUp = []
      this.hideUp = false
      this.saveReceipt.orderReceiptUrl = ''
      this.noneBtnImg = fileList.length >= this.limitCountImg
    },
    // 预览图片
    handleUpPreview(file) {
      this.dialogVisible_saveUpload = true
    },
    // 上传图片
    handleUpUpload(file, fileList) {
      this.hideUp = true
      this.noneBtnImg = fileList.length >= this.limitCountImg
    },
    delimg() {
      this.imgflag = true
      this.imgflagdel = false
      this.imgflagmodel = false
      this.saveReceipt.orderReceiptUrl = ''
    },
    materialNameTleBlur() {
      const tableData = this.materialTableData
      for (var i = 0; i < tableData.length; i++) {
        if (tableData[i].materialName == '') {
          this.$message({
            message: '第' + (i + 1) + '条数据物料名称没有进行填写，请填写',
            type: 'warning',
          })
          return false
        }
      }
    },
    materialSummation() {
      var tableData = this.materialTableData
      const volume = []
      const weight = []
      const pkgQty = []
      for (var i = 0; i < tableData.length; i++) {
        volume.push(tableData[i].volume)
        weight.push(tableData[i].weight)
        pkgQty.push(tableData[i].pkgQty)
      }
      this.waybillDoForm.volume = this.getFloat(eval(volume.join('+')), 4)
      this.waybillDoForm.weight = this.getFloat(eval(weight.join('+')), 4)
      this.waybillDoForm.pkgQty = eval(pkgQty.join('+'))
    },
    getFloat(number, n) {
      n = n ? parseInt(n) : 0
      if (n <= 0) {
        return Math.round(number)
      }
      number = Math.round(number * Math.pow(10, n)) / Math.pow(10, n) //四舍五入
      number = Number(number).toFixed(n) //补足位数
      return number
    },
    edit(row, index) {
      var tableData = this.materialTableData
      for (var i = 0; i < tableData.length; i++) {
        tableData[i].iseditor = true
      }
      this.materialTableData = tableData
    },
    save(row, index) {
      var tableData = this.materialTableData
      for (var i = 0; i < tableData.length; i++) {
        tableData[i].iseditor = false
      }
      this.materialTableData = tableData
      this.materialSummation()
    },
    //导入数据模块————————————————————————————————————————————————————————————————————————
    importExcelData() {
      this.visible = true
    },
    async requestFn(data) {
      this.materialTableData = data
      this.materialSummation()
      return Promise.resolve()
    },
    handleCloseImport() {
      //console.log('弹窗关闭了~')
    },
    handleFinishImport() {
      //console.log('导入完毕了~')
    },
    //导入数据模块——————————————————————————————————————————————————————————————————————————
    addrow() {
      if (this.materialTableData.length == 0) {
        this.materialTableData.push({
          materialName: '',
          volume: 0.0,
          weight: 0.0,
          pkgQty: 0,
          iseditor: true,
        })
      } else {
        const addRowFlag = this.materialNameTleBlur()
        if (addRowFlag == false) {
        } else {
          this.materialTableData.push({
            materialName: '',
            volume: 0.0,
            weight: 0.0,
            pkgQty: 0,
            iseditor: true,
          })
        }
      }
    },
    //获取承运商 再试试
    onInputFocus() {
      //承运商弹出先暂时关掉不可用
      this.companySearch()
      this.dialog_comm_carriers = true
    },
    companySearch() {
      const data = new URLSearchParams()
      data.append('carriersId', this.carriers_form.carriersId)
      data.append('carriersName', this.carriers_form.carriersName)
      data.append('pageSize', this.tableDatadialog_pagination.pageSize)
      data.append('pageNo', this.tableDatadialog_pagination.pageNum)
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/carriers/getBaCarriersAll`,
        data: data,
      }).then(res => {
        this.tableDatadialog = res.data.items
        this.tableDatadialog_pagination.total = res.data.total
      })
    },
    close_dialog_comm_carriers() {
      this.form = {}
      this.pagination2 = {
        pageSize: 5,
        pageNum: 1,
        total: 0,
      }
    },
    closedialogTableVisible1() {
      this.form1 = {}
      this.pagination1 = {
        pageSize: 5,
        pageNum: 1,
        total: 0,
      }
    },
    companyOkay() {},
    //此方法是点击分页时触发的查询，
    handleCurrentChange() {
      this.waybillManagementSearch()
    },
    handleProNoderClick(orderNo) {
      const orderNoData = new URLSearchParams()
      orderNoData.append('orderNo', orderNo.imgurl)
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/waybill/getWaybill`,
        data: orderNoData,
      }).then(res => {
        if (res.data.data.waybillItemDos.length == 0) {
          this.$alert('运单' + orderNo.imgurl + '在新增时并没有输入详细的物料明细！', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
          })
        } else {
          this.materialTableData_JustShow = res.data.data.waybillItemDos
          this.dialog_waybillMan_JustShow_tit = orderNo.imgurl
          this.dialogVisible_JustShow = true
        }
      })
    },
    handleImgClick(imgurl) {
      this.dialogImageUrl_via = `${this.$imageiPPath}` + imgurl.imgurl
      this.dialogImageUrl_via_srcList.push(`${this.$imageiPPath}` + imgurl.imgurl)
      this.dialogVisible_imgUrl_flag = false
      this.dialogVisible_imgUrl = true
    },
    handleCloseImgDialog() {
      this.dialogImageUrl_via = ''
      this.dialogVisible_imgUrl_flag = false
      this.dialogVisible_imgUrl = false
      this.dialogImageUrl_via_srcList.length = 0
    },
    //获取表格和表单数据
    async getTableAndForm() {
      this.waybillManagementSearch()
    },
    handleQuery() {
      //查询
      this.pagination.pageSize = 50
      this.pagination.pageNum = 1
      this.pagination.total = 0
      const data = new URLSearchParams()
      data.append('orderNo', this.AccurateAndUnique_orderNo)
      data.append('pageSize', this.pagination.pageSize)
      data.append('pageNo', this.pagination.pageNum)
      // console.log(data);
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/waybill/queryWaybill`,
        data: data,
      }).then(res => {
        this.tableData = res.data.items
        this.pagination.total = res.data.total
        this.loading = false
      })
    },
    // 获取表格勾选数据
    selectionLineChangeHandle(val) {
      this.merge = this.$refs.cesTable.multipleSelection
    },
    handleClose_waybilladd() {
      this.dialogVisible_waybilladd = false
      this.editFlag = false
      this.saveFlag = false
      this.$refs.waybillDoForm.resetFields()
      this.materialTableData = []
      this.clearBuyerListData()
      this.clearSellerListData()
    },
    clearBuyerListData() {
      this.BuyerList.province = ''
      this.BuyerList.provinceName = ''
      this.BuyerList.city = ''
      this.BuyerList.cityName = ''
      this.BuyerList.area = ''
      this.BuyerList.areaName = ''
    },
    clearSellerListData() {
      this.SellerList.province = ''
      this.SellerList.provinceName = ''
      this.SellerList.city = ''
      this.SellerList.cityName = ''
      this.SellerList.area = ''
      this.SellerList.areaName = ''
    },
    //订单查询精确
    waybillManagementSearch() {
      this.loading = true
      let createTimeBegin
      let createTimeEnd
      if (this.dataForms.createTimeBegin == '' || this.dataForms.createTimeBegin == null) {
        createTimeBegin = ''
      } else {
        createTimeBegin = this.$moment(this.dataForms.createTimeBegin).format('YYYY-MM-DD')
      }
      if (this.dataForms.createTimeEnd == '' || this.dataForms.createTimeEnd == null) {
        createTimeEnd = ''
      } else {
        createTimeEnd = this.$moment(this.dataForms.createTimeEnd).format('YYYY-MM-DD')
      }
      const data = new URLSearchParams()
      data.append('orderNo', this.dataForms.orderNo)
      data.append('vendeeLine', this.dataForms.vendeeLine)
      data.append('batchNo', this.dataForms.batchNo)
      data.append('status', this.dataForms.status)
      data.append('transStatus', this.dataForms.transStatus)
      data.append('companyName', this.dataForms.companyName)
      data.append('vendeeName', this.dataForms.vendeeName)
      data.append('consignName', this.dataForms.consignName)
      data.append('vendeeLinkman', this.dataForms.vendeeLinkman)
      data.append('carrierId', this.dataForms.carrierId)
      data.append('driver', this.dataForms.driver)
      data.append('truckNo', this.dataForms.truckNo)
      data.append('createTimeBegin', createTimeBegin)
      data.append('createTimeEnd', createTimeEnd)
      data.append('proviceAdcode', this.addList.province)
      data.append('cityAdcode', this.addList.city)
      data.append('regionAdcode', this.addList.area)
      data.append('chintBusiness', this.dataForms.chintBusiness)
      data.append('receiptStatus', this.dataForms.receiptStatus)
      data.append('outGoing', this.dataForms.outGoing)
      data.append('pageSize', this.pagination.pageSize)
      data.append('pageNo', this.pagination.pageNum)
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/waybill/queryWaybill`,
        data: data,
      }).then(res => {
        this.tableData = res.data.items
        this.pagination.total = res.data.total
        this.loading = false
      })
    },
    waybillManagementSearch_Multiple() {
      this.pagination.pageSize = 50
      this.pagination.pageNum = 1
      this.pagination.total = 0
      this.waybillManagementSearch()
    },
    //新增订单
    waybillManagementAdd() {
      this.dialog_waybillMan_Title_Flag = '新建运单'
      this.saveAndAddFlag = true
      this.editFlag = false
      this.saveFlag = false
      this.dialogVisible_waybilladd = true
    },
    //修改订单
    waybillManagementModify() {
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '修改操作必须选中一条数据',
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: 'info',
          message: '修改操作只能选择单条数据进行操作',
        })
      } else {
        if (this.merge[0].transStatus == 0) {
          this.dialog_waybillMan_Title_Flag = '修改运单'
          this.saveAndAddFlag = false
          this.editFlag = true
          this.saveFlag = true
          this.dialogVisible_waybilladd = true
          const orderNo = new URLSearchParams()
          orderNo.append('orderNo', this.merge[0].orderNo)
          axios({
            method: 'post',
            url: `${this.$apiPath}/api/urban/waybill/getWaybill`,
            data: orderNo,
          }).then(res => {
            res.data.data.waybillDo.volume = this.getFloat(res.data.data.waybillDo.volume, 4)
            res.data.data.waybillDo.chintBusiness = JSON.stringify(res.data.data.waybillDo.chintBusiness)
            res.data.data.waybillDo.outGoing = JSON.stringify(res.data.data.waybillDo.outGoing)
            let processedData = this.nullToStr(res.data.data.waybillDo)
            this.BuyerList.province = processedData.vendeeProvince
            this.BuyerList.city = processedData.vendeeCity
            this.BuyerList.area = processedData.vendeeDistrict
            this.BuyerList.provinceName = processedData.vendeeProvinceName
            this.BuyerList.cityName = processedData.vendeeCityName
            this.BuyerList.areaName = processedData.vendeeDistrictName
            this.SellerList.province = processedData.consignProvince
            this.SellerList.city = processedData.consignCity
            this.SellerList.area = processedData.consignDistrict
            this.SellerList.provinceName = processedData.consignProvinceName
            this.SellerList.cityName = processedData.consignCityName
            this.SellerList.areaName = processedData.consignDistrictName
            this.waybillDoForm = processedData
            this.waybillDoForm.outGoing = Number(processedData.outGoing)
            for (var i = 0; i < res.data.data.waybillItemDos.length; i++) {
              res.data.data.waybillItemDos[i].iseditor = false
              res.data.data.waybillItemDos[i].volume = this.getFloat(res.data.data.waybillItemDos[i].volume, 4)
            }
            this.materialTableData = res.data.data.waybillItemDos
          })
        } else {
          this.$message({
            type: 'info',
            message: '修改操作只能选择初始状态的单据进行操作',
          })
        }
      }
    },
    nullToStr(data) {
      for (var x in data) {
        if (data[x] === null) {
          // 如果是null 把直接内容转为 ''
          data[x] = ''
        } else {
          if (Array.isArray(data[x])) {
            // 是数组遍历数组 递归继续处理
            data[x] = data[x].map(z => {
              return nullToStr(z)
            })
          }
          if (typeof data[x] === 'object') {
            // 是json 递归继续处理
            data[x] = nullToStr(data[x])
          }
        }
      }
      return data
    },
    //订单发布
    waybillManagementRelease() {
      this.selectionLineChangeHandle()
      var waybilldata = []
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '发布操作必须选中一条数据',
        })
      } else {
        for (var i = 0; i < this.merge.length; i++) {
          waybilldata.push(this.merge[i].id)
        }
        axios({
          method: 'post',
          url: `${this.$apiPath}/api/urban/waybill/publish`,
          data: waybilldata,
        }).then(res => {
          if (res.data.success == true) {
            this.$message({
              message: '发布成功',
              type: 'success',
            })
            this.waybillManagementSearch()
          } else {
            this.$alert(res.data.msg, '提示', {
              confirmButtonText: '确定',
            })
          }
        })
      }
    },
    //发布回退
    waybillPublishFallback() {
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '发布回退操作必须选中一条数据',
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: 'info',
          message: '发布回退操作只能选择单条数据进行操作',
        })
      } else {
        if (this.merge[0].transStatus == 0) {
          this.$message({
            type: 'info',
            message: '该订单已是初始状态，不需要发布回退',
          })
        } else {
          this.$confirm('此操作将对单据进行发布回退, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              const waybilldata = new URLSearchParams()
              waybilldata.append('orderNo', this.merge[0].orderNo)
              axios({
                method: 'post',
                url: `${this.$apiPath}/api/urban/waybill/publishBack`,
                data: waybilldata,
              }).then(res => {
                if (res.data.success == true) {
                  this.$message({
                    message: '订单发布回退成功',
                    type: 'success',
                  })
                  this.waybillManagementSearch()
                } else {
                  this.$alert(res.data.msg, '提示', {
                    confirmButtonText: '确定',
                  })
                }
              })
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消撤单',
              })
            })
        }
      }
    },
    // 短信
    handleNote() {
      this.selectionLineChangeHandle()
      if (this.merge.length === 0) {
        this.$message({
          type: 'info',
          message: '短信操作必须选中一条数据',
        })
      } else {
        this.noteVisible = true
      }
    },
    handleCloseNote() {
      this.receive = 1
      this.delivery = 1
    },
    handleUpdate() {
      this.selectionLineChangeHandle()
      const orderNos = this.merge.map(e => e.orderNo)
      const params = {
        orderNos,
        arriveMsgFlag: this.delivery,
        dispatchMsgFlag: this.receive,
      }
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/waybill/updateWayBillsMsgFlag`,
        data: params,
      }).then(res => {
        if (res.data.success === true) {
          this.$message({
            message: '成功',
            type: 'success',
          })
          this.noteVisible = false
          this.waybillManagementSearch()
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定',
          })
        }
      })
    },
    //送达
    waybillManagementService() {
      this.selectionLineChangeHandle()
      var preOrderNos = []
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '送达操作必须选中一条数据',
        })
      } else {
        for (var i = 0; i < this.merge.length; i++) {
          if (this.merge[i].transStatus == 6) {
            preOrderNos.push(this.merge[i].orderNo)
          } else {
            this.$message({
              type: 'info',
              message: '您所勾选的订单存在不是派送中的单据，请重新勾选',
            })
            return false
          }
        }
        axios({
          method: 'post',
          url: `${this.$apiPath}/api/urban/waybill/deliveryWayBill`,
          data: preOrderNos,
        }).then(res => {
          if (res.data.success == true) {
            this.$message({
              message: '送达成功',
              type: 'success',
            })
            this.waybillManagementSearch()
          } else {
            this.$alert(res.data.msg, '提示', {
              confirmButtonText: '确定',
            })
          }
        })
      }
    },
    //撤单
    waybillManagementCancel() {
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '撤单操作必须选中一条数据',
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: 'info',
          message: '撤单操作只能选择单条数据进行操作',
        })
      } else {
        this.$confirm('此操作将对单据进行撤单, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            const waybilldata = new URLSearchParams()
            waybilldata.append('orderNo', this.merge[0].orderNo)
            axios({
              method: 'post',
              url: `${this.$apiPath}/api/urban/waybill/revokeWayBill`,
              data: waybilldata,
            }).then(res => {
              if (res.data.success == true) {
                this.$message({
                  message: '撤单成功',
                  type: 'success',
                })
                this.waybillManagementSearch()
              } else {
                this.$alert(res.data.msg, '提示', {
                  confirmButtonText: '确定',
                })
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消撤单',
            })
          })
      }
    },
    //揽收
    waybillManagementCollect() {
      this.selectionLineChangeHandle()
      var preOrderNos = []
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '合单操作必须选中一条数据',
        })
      } else {
        for (var i = 0; i < this.merge.length; i++) {
          preOrderNos.push(this.merge[i].orderNo)
        }
        axios({
          method: 'post',
          url: `${this.$apiPath}/api/urban/waybill/receive`,
          data: preOrderNos,
        }).then(res => {
          if (res.data.success == true) {
            this.$message({
              message: '揽收成功',
              type: 'success',
            })
            this.waybillManagementSearch()
          } else {
            this.$alert(res.data.msg, '提示', {
              confirmButtonText: '确定',
            })
          }
        })
      }
    },
    //揽收回退
    waybillManagementCollectBack() {
      this.selectionLineChangeHandle()
      var preOrderNos = []
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '揽收回退操作必须选中一条数据',
        })
      } else {
        for (var i = 0; i < this.merge.length; i++) {
          preOrderNos.push(this.merge[i].orderNo)
        }
        axios({
          method: 'post',
          url: `${this.$apiPath}/api/urban/waybill/receive/cancel`,
          data: preOrderNos,
        }).then(res => {
          if (res.data.success == true) {
            this.$message({
              message: '揽收回退成功',
              type: 'success',
            })
            this.waybillManagementSearch()
          } else {
            this.$alert(res.data.msg, '提示', {
              confirmButtonText: '确定',
            })
          }
        })
      }
    },
    savewaybill() {
      this.$refs['waybillDoForm'].validate(valid => {
        if (valid) {
          // if(this.BuyerList.province == ''){
          //     this.$message.error('买家省市区信息填写不完整');
          //     return false
          // }else if(this.BuyerList.city == ''){
          //     this.$message.error('买家省市区信息填写不完整');
          //     return false
          // }
          //
          if (this.SellerList.province == '') {
            this.$message.error('卖家省市区信息填写不完整')
            return false
          } else if (this.SellerList.city == '') {
            this.$message.error('卖家省市区信息填写不完整')
            return false
          }

          if (this.waybillDoForm.pkgQty <= 0 || this.waybillDoForm.weight <= 0 || this.waybillDoForm.volume <= 0) {
            this.$message({
              message: '总件数、总重量、总体积必须全部大于0的值',
              type: 'warning',
            })
          } else {
            //运单新增，保存完直接关闭
            let saveParmas = {}
            saveParmas.waybillDo = this.waybillDoForm
            saveParmas.waybillItemDos = this.materialTableData
            if (this.dialog_waybillMan_Title_Flag == '新建运单') {
              //表示新增
              const addRowFlag = this.materialNameTleBlur()
              if (addRowFlag == false) {
              } else {
                axios({
                  method: 'post',
                  url: `${this.$apiPath}/api/urban/waybill/createWayBill`,
                  data: saveParmas,
                }).then(res => {
                  if (res.data.success == true) {
                    this.$message({
                      message: res.data.msg + '，运单号:' + res.data.data,
                      type: 'success',
                    })
                    this.handleClose_waybilladd()
                    this.waybillManagementSearch()
                  } else {
                    this.$alert(res.data.msg, '提示', {
                      confirmButtonText: '确定',
                    })
                  }
                })
              }
            } else if (this.dialog_waybillMan_Title_Flag == '修改运单') {
              const addRowFlag = this.materialNameTleBlur()
              if (addRowFlag == false) {
              } else {
                //表示修改
                axios({
                  method: 'post',
                  url: `${this.$apiPath}/api/urban/waybill/updateWayBill`,
                  data: saveParmas,
                }).then(res => {
                  if (res.data.success == true) {
                    this.$message({
                      message: '修改成功',
                      type: 'success',
                    })
                    this.handleClose_waybilladd()
                    this.waybillManagementSearch()
                  } else {
                    this.$alert(res.data.msg, '提示', {
                      confirmButtonText: '确定',
                    })
                  }
                })
              }
            }
          }
        } else {
          this.$message.error('请检查您输入的数据,请核对后重试!')
        }
      })
    },
    saveAndAdd() {
      this.$refs['waybillDoForm'].validate(valid => {
        if (valid) {
          // if(this.BuyerList.province == ''){
          //     this.$message.error('买家省市区信息填写不完整');
          //     return false
          // }else if(this.BuyerList.city == ''){
          //     this.$message.error('买家省市区信息填写不完整');
          //     return false
          // }

          if (this.SellerList.province == '') {
            this.$message.error('卖家省市区信息填写不完整')
            return false
          } else if (this.SellerList.city == '') {
            this.$message.error('卖家省市区信息填写不完整')
            return false
          }

          if (this.waybillDoForm.pkgQty <= 0 || this.waybillDoForm.weight <= 0 || this.waybillDoForm.volume <= 0) {
            this.$message({
              message: '总件数、总重量、总体积必须全部大于0的值',
              type: 'warning',
            })
          } else {
            let saveParmas = {}
            saveParmas.waybillDo = this.waybillDoForm
            saveParmas.waybillItemDos = this.materialTableData
            //表示新增,且不关闭窗口，并且保留发货方信息
            const addRowFlag = this.materialNameTleBlur()
            if (addRowFlag == false) {
            } else {
              axios({
                method: 'post',
                url: `${this.$apiPath}/api/urban/waybill/createWayBill`,
                data: saveParmas,
              }).then(res => {
                if (res.data.success == true) {
                  this.$message({
                    message: res.data.msg + '，运单号:' + res.data.data,
                    type: 'success',
                  })
                  //除发货方/和收货方信息之外全部清空
                  // this.waybillDoForm.vendeeName = '';
                  // this.waybillDoForm.vendeeAddress = '';
                  // this.waybillDoForm.vendeeLinkman = '';
                  // this.waybillDoForm.vendeePhone = '';
                  // this.waybillDoForm.vendeeMobile = '';
                  // this.waybillDoForm.requiredDeliveryTime = '';
                  // this.waybillDoForm.vendeeLine = '';
                  this.waybillDoForm.pkgQty = ''
                  this.waybillDoForm.weight = ''
                  this.waybillDoForm.volume = ''
                  this.waybillDoForm.wunit = ''
                  this.waybillDoForm.vunit = ''
                  this.materialTableData = []
                } else {
                  this.$alert(res.data.msg, '提示', {
                    confirmButtonText: '确定',
                  })
                }
              })
            }
          }
        } else {
          this.$message.error('请检查您输入的数据,请核对后重试!')
        }
      })
    },

    // 清除收货方信息
    header_clear() {
      this.waybillDoForm.vendeeName = ''
      this.waybillDoForm.vendeeAddress = ''
      this.waybillDoForm.vendeeLinkman = ''
      this.waybillDoForm.vendeePhone = ''
      this.waybillDoForm.vendeeMobile = ''
      this.waybillDoForm.requiredDeliveryTime = ''
      this.waybillDoForm.vendeeLine = ''
      this.BuyerList.province = ''
      this.BuyerList.city = ''
      this.BuyerList.area = ''
      this.waybillDoForm.vendeeLine = ''
      this.waybillDoForm.outGoing = ''
      this.waybillDoForm.timelinessType = ''
      this.waybillDoForm.timeliness = ''
      this.waybillDoForm.lineType = ''
    },
    // 清除发货方信息
    header_clearone() {
      this.waybillDoForm.consignLinkman = ''
      this.waybillDoForm.consignMobile = ''
      this.waybillDoForm.consignPhone = ''
      this.waybillDoForm.chintBusiness = ''
      this.waybillDoForm.consignName = ''
      this.waybillDoForm.replyDeliveryTime = ''
      this.waybillDoForm.remark = ''
      // this.waybillDoForm.outGoing = ''
      this.waybillDoForm.consignAddress = ''
      this.SellerList.province = ''
      this.SellerList.city = ''
      this.SellerList.area = ''
      this.SellerList.vendeeLine = ''
      this.SellerList.lineType = ''
      this.SellerList.timelinessType = ''
      this.SellerList.timeliness = ''
    },
    //上传回单
    waybillManagementReceiptUpload() {
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '回单上传操作必须选中一条数据',
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: 'info',
          message: '回单上传操作只能选择单条数据进行操作',
        })
      } else {
        if (this.merge[0].transStatus == 6 || this.merge[0].transStatus == 8) {
          this.saveReceipt.orderNo = this.merge[0].orderNo
          this.dialog_waybillManagementReceiptUpload = true
        } else {
          this.$message({
            type: 'info',
            message: '回单上传操作只能选择派送中或已送达的单据进行操作',
          })
        }
      }
    },
    //todo 导出功能待后端校验
    derive() {
      var loading = this.$loading({
        lock: true,
        text: '导出中，请稍后',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/waybill/exportWayBillTimeEfficiency`,
        responseType: 'arraybuffer',
      })
        .then(res => {
          let url = window.URL.createObjectURL(new Blob([res.data], { type: '.xlsx' }))
          console.log(res.data)
          let a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          //  这个名字你改下对 我看看后端有没有给你返回
          let temp = res.headers['content-disposition']?.split(';')[1].split('=')[1]
          let fileName = decodeURI(temp)
          a.setAttribute('download', fileName)
          document.body.appendChild(a)
          a.click()
          url = window.URL.revokeObjectURL(url)
          document.body.removeChild(a)
          loading.close()
        })
        .catch(error => {
          this.$message.error('导出失败')
          loading.close()
        })
    },
    //导出
    waybillManagementExport() {
      // 导出去掉勾选的数据，按照高级查询的数据给后台，后台查出多少数据导出多少数据，去掉pageSize、pageNo参数，因为前端不做分页显示，其余参数与高级查询一致
      let createTimeBegin
      let createTimeEnd
      if (this.dataForms.createTimeBegin == '' || this.dataForms.createTimeBegin == null) {
        createTimeBegin = ''
      } else {
        createTimeBegin = this.$moment(this.dataForms.createTimeBegin).format('YYYY-MM-DD')
      }
      if (this.dataForms.createTimeEnd == '' || this.dataForms.createTimeEnd == null) {
        createTimeEnd = ''
      } else {
        createTimeEnd = this.$moment(this.dataForms.createTimeEnd).format('YYYY-MM-DD')
      }
      const data = new URLSearchParams()
      data.append('orderNo', this.dataForms.orderNo)
      data.append('vendeeLine', this.dataForms.vendeeLine)
      data.append('batchNo', this.dataForms.batchNo)
      data.append('status', this.dataForms.status)
      data.append('transStatus', this.dataForms.transStatus)
      data.append('companyName', this.dataForms.companyName)
      data.append('vendeeName', this.dataForms.vendeeName)
      data.append('consignName', this.dataForms.consignName)
      data.append('carrierId', this.dataForms.carrierId)
      data.append('driver', this.dataForms.driver)
      data.append('truckNo', this.dataForms.truckNo)
      data.append('createTimeBegin', createTimeBegin)
      data.append('createTimeEnd', createTimeEnd)
      data.append('proviceAdcode', this.addList.province)
      data.append('cityAdcode', this.addList.city)
      data.append('regionAdcode', this.addList.area)
      data.append('chintBusiness', this.dataForms.chintBusiness)
      data.append('outGoing', this.dataForms.outGoing)

      var loading = this.$loading({
        lock: true,
        text: '导出中，请稍后',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      // console.log(data);
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/waybill/checkExportCount`,
        data: data,
      }).then(res => {
        if (res.data.success == true) {
          axios({
            method: 'post',
            url: `${this.$apiPath}/api/urban/waybill/exportWayBill`,
            data: data,
            responseType: 'arraybuffer',
          })
            .then(res => {
              let url = window.URL.createObjectURL(new Blob([res.data], { type: '.xlsx' }))
              console.log(res.data)
              let a = document.createElement('a')
              a.style.display = 'none'
              a.href = url
              a.setAttribute('download', `运单报表.xlsx`)
              document.body.appendChild(a)
              a.click()
              url = window.URL.revokeObjectURL(url)
              document.body.removeChild(a)
              loading.close()
            })
            .catch(error => {
              this.$message.error('导出失败')
              loading.close()
            })
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定',
          })
          loading.close()
        }
      })
    },
    //按时间筛选导出运单时效导出
    exportWayBillTimeEfficiencyByDate() {
      // 导出去掉勾选的数据，按照高级查询的数据给后台，后台查出多少数据导出多少数据，
      // 去掉pageSize、pageNo参数，因为前端不做分页显示，其余参数与高级查询一致
      // 按时间查询最多相差31天的数据
      let createTimeBegin
      let createTimeEnd
      if (this.dataForms.createTimeBegin == '' || this.dataForms.createTimeBegin == null) {
        createTimeBegin = ''
      } else {
        createTimeBegin = this.$moment(this.dataForms.createTimeBegin).format('YYYY-MM-DD')
      }
      if (this.dataForms.createTimeEnd == '' || this.dataForms.createTimeEnd == null) {
        createTimeEnd = ''
      } else {
        createTimeEnd = this.$moment(this.dataForms.createTimeEnd).format('YYYY-MM-DD')
      }
      const data = new URLSearchParams()
      data.append('orderNo', this.dataForms.orderNo)
      data.append('vendeeLine', this.dataForms.vendeeLine)
      data.append('batchNo', this.dataForms.batchNo)
      data.append('status', this.dataForms.status)
      data.append('transStatus', this.dataForms.transStatus)
      data.append('companyName', this.dataForms.companyName)
      data.append('vendeeName', this.dataForms.vendeeName)
      data.append('consignName', this.dataForms.consignName)
      data.append('carrierId', this.dataForms.carrierId)
      data.append('driver', this.dataForms.driver)
      data.append('truckNo', this.dataForms.truckNo)
      data.append('createTimeBegin', createTimeBegin)
      data.append('createTimeEnd', createTimeEnd)
      data.append('proviceAdcode', this.addList.province)
      data.append('cityAdcode', this.addList.city)
      data.append('regionAdcode', this.addList.area)
      data.append('chintBusiness', this.dataForms.chintBusiness)
      data.append('outGoing', this.dataForms.outGoing)

      var loading = this.$loading({
        lock: true,
        text: '导出中，请稍后',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      // console.log(data);
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/waybill/checkWayBillTimeEfficiency`,
        data: data,
      }).then(res => {
        if (res.data.success == true) {
          axios({
            method: 'post',
            url: `${this.$apiPath}/api/urban/waybill/exportWayBillTimeEfficiencyByDate`,
            data: data,
            responseType: 'arraybuffer',
          })
            .then(res => {
              let url = window.URL.createObjectURL(new Blob([res.data], { type: '.xlsx' }))
              console.log(res.data)
              let a = document.createElement('a')
              a.style.display = 'none'
              a.href = url
              a.setAttribute('download', `运单时效报表.xlsx`)
              document.body.appendChild(a)
              a.click()
              url = window.URL.revokeObjectURL(url)
              document.body.removeChild(a)
              loading.close()
            })
            .catch(error => {
              this.$message.error('导出失败')
              loading.close()
            })
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定',
          })
          loading.close()
        }
      })
    },

    //托运单打印
    consignment() {
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '托运单打印必须选中一条数据',
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: 'info',
          message: '托运单打印只能选择单条数据进行操作',
        })
      } else {
        const routeUrlHref = `${this.$apiPath}/api/urban/reports/export/transportInfoPdf?orderNo=` + this.merge[0].orderNo
        window.open(routeUrlHref, '_blank')
      }
    },
    //托运单五联纸打印
    immovable() {
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '托运单打印必须选中一条数据',
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: 'info',
          message: '托运单打印只能选择单条数据进行操作',
        })
      } else {
        const routeUrlHref = `${this.$apiPath}/api/urban/reports/export/transportInfoGufenPdf?orderNo=` + this.merge[0].orderNo
        window.open(routeUrlHref, '_blank')
      }
    },
    isAllEqual(array) {
      if (array.length > 0) {
        return !array.some(function (value, index) {
          return value !== array[0]
        })
      } else {
        return true
      }
    },
    //交接单打印
    handover() {
      this.selectionLineChangeHandle()

      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '交接单打印必须选中一条数据',
        })
      } else {
        let orderNos = ''
        const carriersIdlist = []
        for (var i = 0; i < this.merge.length; i++) {
          if (this.merge[i].assignStatus == 0) {
            this.$message({
              message: '您勾选的单据中有未派车的单据，不能打印交接单！',
              type: 'info',
            })
            return false
          } else {
            carriersIdlist.push(this.merge[i].carriersId)
            orderNos += this.merge[i].orderNo + ','
          }
        }
        if (this.isAllEqual(carriersIdlist) == false) {
          this.$message({
            message: '您勾选的多条单据中不是同一个承运商，不能打印交接单，请检查！',
            type: 'info',
          })
          return false
        } else {
          if (orderNos.length > 0) {
            orderNos = orderNos.substr(0, orderNos.length - 1)
          }
          const routeUrlHref = `${this.$apiPath}/api/urban/reports/export/handoverPapersPdf?orderNos=` + orderNos
          window.open(routeUrlHref, '_blank')
        }
      }
    },

    receiptConfirmation() {
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '回单确认操作必须选中一条数据',
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: 'info',
          message: '回单确认操作只能选择单条数据进行操作',
        })
      } else {
        if (this.merge[0].orderReceiptUrl == '' || this.merge[0].orderReceiptUrl == null || this.merge[0].orderReceiptUrl == 'null') {
          this.$message({
            type: 'info',
            message: '回单确认操作只能选取已有回单附件的单据进行操作',
          })
        } else if (this.merge[0].receiptStatus == 3) {
          this.$message({
            type: 'info',
            message: '该回单已确认，不允许继续操作！',
          })
        } else {
          this.dialogVisible_orderNo = '运单号：' + this.merge[0].orderNo
          this.dialogImageUrl_via = `${this.$imageiPPath}` + this.merge[0].orderReceiptUrl
          this.dialogImageUrl_via_srcList.push(`${this.$imageiPPath}` + this.merge[0].orderReceiptUrl)
          this.dialogVisible_imgUrl_flag = true
          this.dialogVisible_imgUrl = true
        }
      }
    },
    //重置按钮
    resetFrom() {
      this.$refs['dataForms'].resetFields()
    },
    //回单通过
    SavereceiptPassed() {
      const uploadData = {}
      uploadData.orderNo = this.merge[0].orderNo
      uploadData.receiptStatus = '3'
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/waybill/confirmReceipt`,
        data: uploadData,
      }).then(res => {
        if (res.data.success == true) {
          this.$message({
            message: '回单已确认通过',
            type: 'success',
          })
          this.handleCloseImgDialog()
          this.waybillManagementSearch()
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定',
          })
        }
      })
    },
    //回单驳回
    Savereceiptreject() {
      const uploadData = {}
      uploadData.orderNo = this.merge[0].orderNo
      uploadData.receiptStatus = '2'
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/waybill/confirmReceipt`,
        data: uploadData,
      }).then(res => {
        if (res.data.success == true) {
          this.$message({
            message: '回单已驳回',
            type: 'success',
          })
          this.handleCloseImgDialog()
          this.waybillManagementSearch()
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定',
          })
        }
      })
    },
    handleSearch() {
      this.dialog = true
      let form = localStorage.getItem('way')
      if (form) {
        let obj = JSON.parse(form)
        for (let key in obj) {
          this.$set(this.dataForms, key, obj[key])
        }
      }
    },
    handleClose(done) {
      if (this.loading) {
        return
      }
      this.loading = true
      this.timer = setTimeout(() => {
        // 动画关闭需要一定的时间
        setTimeout(() => {
          this.loading = false
        }, 400)
        this.waybillManagementSearch_Multiple()
        localStorage.setItem('way', JSON.stringify(this.dataForms))
        // 动画关闭需要一定的时间
        this.dialog = false
      }, 2000)
    },
    cancelForm() {
      this.loading = false
      this.dialog = false
      this.addList.province = ''
      this.addList.city = ''
      this.addList.area = ''
      clearTimeout(this.timer)
    },
    handleDelete(val) {},
    // checkbox的触发接收
    handleSelectionChange(val) {
      this.conData = val.val
    },
    // checkbox的触发接收
    handleSelectionChange1(val) {
      this.veeData = val.val
    },
    // 双击事件的行触发
    clickTable(val) {
      this.waybillDoForm.consignId = val.row.carriersId
      this.waybillDoForm.consignName = val.row.carriersName
      this.dialog_comm_carriers = false
    },
    //button的触发
    handleEdit(val) {},
    handleClose_waybillManagementReceiptUpload() {
      this.dialog_waybillManagementReceiptUpload = false
      this.imgflagmodel = false
      this.imgflagdel = false
      this.imgflag = true
      this.srcList.length = 0
    },
    save_Upload() {
      const uploadData = new URLSearchParams()
      uploadData.append('orderNo', this.saveReceipt.orderNo)
      uploadData.append('orderReceiptUrl', this.saveReceipt.orderReceiptUrl)
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/waybill/saveReceipt`,
        data: uploadData,
      }).then(res => {
        if (res.data.success == true) {
          this.$message({
            message: '上传成功',
            type: 'success',
          })
          this.handleClose_waybillManagementReceiptUpload()
          this.waybillManagementSearch()
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定',
          })
        }
      })
    },
  },
}
</script>
<style>
.tableMain .ces-table-page {
  height: 100%;
}
.tableMain .ces-table {
  height: 100%;
}
.tableMain .el-table th {
  padding: 0;
  height: 35px;
}
.tableMain .el-table td {
  padding: 0;
  height: 35px;
}
.waybill_add .ces-table {
  height: 200px;
}
.waybill_add .el-table th {
  padding: 0;
  height: 30px;
}
.waybill_add .el-table td {
  padding: 0;
  height: 30px;
}
.materialTableData .ces-table {
  height: 200px;
}
.materialTableData .el-table th {
  padding: 0;
  height: 30px;
}
.materialTableData .el-table td {
  padding: 0;
  height: 30px;
}
.materialTableData .el-input__inner {
  height: 30px;
}
</style>
<style scoped>
.boxbutton {
  width: 100%;
  height: 40px;
}
.tableMain {
  width: 100%;
  height: 82%;
}
</style>
